import * as constants from 'data/constants/recommendations';

import { externalBaseUrl } from 'config';

export const fetchSiteEpisodes = (siteId, active, sort) => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.SITE_EPISODES_LOAD_START });
  try {
    const res = await api.get(`${externalBaseUrl}/sites/${siteId}/episodes/`, { params: { active, ...sort } });

    dispatch({
      type: constants.SITE_EPISODES_LOAD_SUCCESS,
      payload: normalize(res.data.items, [schema.episode]),
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: constants.SITE_EPISODES_LOAD_FAILED });
    throw e;
  }
};

export const fetchEpisodeDays = episodeId => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.EPISODE_DAYS_LOAD_START });
  try {
    const res = await api.get(`${externalBaseUrl}/episodes/${episodeId}/days`);

    dispatch({
      type: constants.EPISODE_DAYS_LOAD_SUCCESS,
      payload: normalize(res.data, [schema.episodeDay]),
      episodeId: episodeId.toString(),
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: constants.EPISODE_DAYS_LOAD_FAILED });
    throw e;
  }
};

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
export const clear = () => ({ type: constants.CLEAR });
