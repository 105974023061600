const prefix = type => `opu/gateways/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LIST_DOWNLOAD_START = prefix('LIST_DOWNLOAD_START');
export const LIST_DOWNLOAD_SUCCESS = prefix('LIST_DOWNLOAD_SUCCESS');
export const LIST_DOWNLOAD_FAILED = prefix('LIST_DOWNLOAD_FAILED');

export const STATS_LOAD_START = prefix('STATS_LOAD_START');
export const STATS_LOAD_SUCCESS = prefix('STATS_LOAD_SUCCESS');
export const STATS_LOAD_FAILED = prefix('STATS_LOAD_FAILED');

export const STATUS_TIMELINE_LOAD_START = prefix('STATUS_TIMELINE_LOAD_START');
export const STATUS_TIMELINE_LOAD_SUCCESS = prefix('STATUS_TIMELINE_LOAD_SUCCESS');
export const STATUS_TIMELINE_LOAD_FAILED = prefix('STATUS_TIMELINE_LOAD_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_STATS = prefix('CLEAR_STATS');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
