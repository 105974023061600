import * as constants from 'data/constants/monitors';
import { saveBlob } from 'data/utils/actions';

import { externalBaseUrl } from 'config';

export const fetchList = (page, perPage, search, systemId, online, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get('devices/', {
      params: { page, perPage, search, system_id: systemId, online, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.monitor]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};

export const fetchSingle = id => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.LOAD_START });
  try {
    const res = await api.get(`/devices/${id}/`);

    dispatch({
      type: constants.LOAD_SUCCESS,
      payload: normalize(res.data, schema.monitor),
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.LOAD_FAILED });
  }
};

export const downloadList = (fileType, search, systemId, online) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.LIST_DOWNLOAD_START });
  try {
    const res = await api.get('devices/download/', {
      params: { file_type: fileType, search, system_id: systemId, online },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.LIST_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'monitors-list', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_DOWNLOAD_FAILED });

    return e;
  }
};

export const fetchAvailabilityCough = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.AVAILABILITY_COUGH_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/availability/`, {
      params: { sensor: 0, start_date: from, end_date: to },
    });

    dispatch({
      type: constants.AVAILABILITY_COUGH_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.AVAILABILITY_COUGH_LOAD_FAILED });
  }
};

export const fetchAvailabilityTemperature = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.AVAILABILITY_TEMPERATURE_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/availability/`, {
      params: { sensor: 1, start_date: from, end_date: to },
    });

    dispatch({
      type: constants.AVAILABILITY_TEMPERATURE_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.AVAILABILITY_TEMPERATURE_LOAD_FAILED });
  }
};

export const fetchAvailabilityStatus = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.AVAILABILITY_STATUS_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/status-timeline/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.AVAILABILITY_STATUS_LOAD_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.AVAILABILITY_STATUS_LOAD_FAILED });
  }
};

export const fetchRhs = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.RHS_DATA_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/data/rhs/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.RHS_DATA_LOAD_SUCCESS,
      payload: { data: res.data, id },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.RHS_DATA_LOAD_FAILED });
  }
};

export const fetchTemperature = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.TEMPERATURE_DATA_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/data/temperature/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.TEMPERATURE_DATA_LOAD_SUCCESS,
      payload: { data: res.data, id },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.TEMPERATURE_DATA_LOAD_FAILED });
  }
};

export const fetchHumidity = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.HUMIDITY_DATA_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/data/humidity/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.HUMIDITY_DATA_LOAD_SUCCESS,
      payload: { data: res.data, id },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.HUMIDITY_DATA_LOAD_FAILED });
  }
};

const sensors = {
  0: 'COUGH',
  1: 'TEMPERATURE',
};

export const fetchHeatmapData = (id, sensor, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants[`HEATMAP_${sensors[sensor]}_LOAD_START`] });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/data/heatmap/`, {
      params: { sensor, start_date: from, end_date: to },
    });

    dispatch({
      type: constants[`HEATMAP_${sensors[sensor]}_LOAD_SUCCESS`],
      payload: { data: res.data.data },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants[`HEATMAP_${sensors[sensor]}_LOAD_FAILED`] });
  }
};

export const fetchStats = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.STATS_LOAD_START });
  try {
    const res = await api.get('devices/stats/');

    dispatch({
      type: constants.STATS_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.STATS_LOAD_FAILED });

    return e;
  }
};

export const fetchColorHistory = (id, sensor, timeframe, page, perPage) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.COLOR_HISTORY_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/data/colors/`, {
      params: { sensor, page, perPage, start_date: from, end_date: to },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.colorHistoryItem]),
    };

    dispatch({
      type: constants.COLOR_HISTORY_LOAD_SUCCESS,
      payload,
    });

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.COLOR_HISTORY_LOAD_FAILED });

    return e;
  }
};

export const downloadData = (id, timeframe, fileType) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.DATA_DOWNLOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`devices/${id}/download/`, {
      params: { start_date: from, end_date: to, file_type: fileType },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.DATA_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'monitor-data', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);

    dispatch({ type: constants.DATA_DOWNLOAD_FAILED });

    return e;
  }
};

export const activateSubscription = (siteId, monitoId) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.ACTIVATE_SUBSCRIPTION_START });
  try {
    const res = await api.patch(`${externalBaseUrl}/api/v2/sites/${siteId}/devices/licenses/${monitoId}/activate`);
    if (res.status !== 200) throw new Error(res.statusText);

    dispatch({
      type: constants.ACTIVATE_SUBSCRIPTION_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);

    dispatch({ type: constants.ACTIVATE_SUBSCRIPTION_FAILED });

    return false;
  }
};

export const clearRhsTemperature = () => ({ type: constants.CLEAR_RHS_TEMPERATURE });

export const clearStats = () => ({ type: constants.CLEAR_STATS });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
