const prefix = type => `opu/monitors/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LIST_DOWNLOAD_START = prefix('LIST_DOWNLOAD_START');
export const LIST_DOWNLOAD_SUCCESS = prefix('LIST_DOWNLOAD_SUCCESS');
export const LIST_DOWNLOAD_FAILED = prefix('LIST_DOWNLOAD_FAILED');

export const AVAILABILITY_LOAD_START = prefix('AVAILABILITY_LOAD_START');
export const AVAILABILITY_LOAD_SUCCESS = prefix('AVAILABILITY_LOAD_SUCCESS');
export const AVAILABILITY_LOAD_FAILED = prefix('AVAILABILITY_LOAD_FAILED');

export const AVAILABILITY_COUGH_LOAD_START = prefix('AVAILABILITY_COUGH_LOAD_START');
export const AVAILABILITY_COUGH_LOAD_SUCCESS = prefix('AVAILABILITY_COUGH_LOAD_SUCCESS');
export const AVAILABILITY_COUGH_LOAD_FAILED = prefix('AVAILABILITY_COUGH_LOAD_FAILED');

export const AVAILABILITY_TEMPERATURE_LOAD_START = prefix('AVAILABILITY_TEMPERATURE_LOAD_START');
export const AVAILABILITY_TEMPERATURE_LOAD_SUCCESS = prefix('AVAILABILITY_TEMPERATURE_LOAD_SUCCESS');
export const AVAILABILITY_TEMPERATURE_LOAD_FAILED = prefix('AVAILABILITY_TEMPERATURE_LOAD_FAILED');

export const AVAILABILITY_STATUS_LOAD_START = prefix('AVAILABILITY_STATUS_LOAD_START');
export const AVAILABILITY_STATUS_LOAD_SUCCESS = prefix('AVAILABILITY_STATUS_LOAD_SUCCESS');
export const AVAILABILITY_STATUS_LOAD_FAILED = prefix('AVAILABILITY_STATUS_LOAD_FAILED');

export const RHS_DATA_LOAD_START = prefix('RHS_DATA_LOAD_START');
export const RHS_DATA_LOAD_SUCCESS = prefix('RHS_DATA_LOAD_SUCCESS');
export const RHS_DATA_LOAD_FAILED = prefix('RHS_DATA_LOAD_FAILED');

export const TEMPERATURE_DATA_LOAD_START = prefix('TEMPERATURE_DATA_LOAD_START');
export const TEMPERATURE_DATA_LOAD_SUCCESS = prefix('TEMPERATURE_DATA_LOAD_SUCCESS');
export const TEMPERATURE_DATA_LOAD_FAILED = prefix('TEMPERATURE_DATA_LOAD_FAILED');

export const HUMIDITY_DATA_LOAD_START = prefix('HUMIDITY_DATA_LOAD_START');
export const HUMIDITY_DATA_LOAD_SUCCESS = prefix('HUMIDITY_DATA_LOAD_SUCCESS');
export const HUMIDITY_DATA_LOAD_FAILED = prefix('HUMIDITY_DATA_LOAD_FAILED');

export const STATS_LOAD_START = prefix('STATS_LOAD_START');
export const STATS_LOAD_SUCCESS = prefix('STATS_LOAD_SUCCESS');
export const STATS_LOAD_FAILED = prefix('STATS_LOAD_FAILED');

export const HEATMAP_COUGH_LOAD_START = prefix('HEATMAP_COUGH_LOAD_START');
export const HEATMAP_COUGH_LOAD_SUCCESS = prefix('HEATMAP_COUGH_LOAD_SUCCESS');
export const HEATMAP_COUGH_LOAD_FAILED = prefix('HEATMAP_COUGH_LOAD_FAILED');

export const HEATMAP_TEMPERATURE_LOAD_START = prefix('HEATMAP_TEMPERATURE_LOAD_START');
export const HEATMAP_TEMPERATURE_LOAD_SUCCESS = prefix('HEATMAP_TEMPERATURE_LOAD_SUCCESS');
export const HEATMAP_TEMPERATURE_LOAD_FAILED = prefix('HEATMAP_TEMPERATURE_LOAD_FAILED');

export const COLOR_HISTORY_LOAD_START = prefix('COLOR_HISTORY_LOAD_START');
export const COLOR_HISTORY_LOAD_SUCCESS = prefix('COLOR_HISTORY_LOAD_SUCCESS');
export const COLOR_HISTORY_LOAD_FAILED = prefix('COLOR_HISTORY_LOAD_FAILED');

export const COLOR_HISTORY_DOWNLOAD_START = prefix('COLOR_HISTORY_DOWNLOAD_START');
export const COLOR_HISTORY_DOWNLOAD_SUCCESS = prefix('COLOR_HISTORY_DOWNLOAD_SUCCESS');
export const COLOR_HISTORY_DOWNLOAD_FAILED = prefix('COLOR_HISTORY_DOWNLOAD_FAILED');

export const DATA_DOWNLOAD_START = prefix('DATA_DOWNLOAD_START');
export const DATA_DOWNLOAD_SUCCESS = prefix('DATA_DOWNLOAD_SUCCESS');
export const DATA_DOWNLOAD_FAILED = prefix('DATA_DOWNLOAD_FAILED');

export const CLEAR_RHS_TEMPERATURE = prefix('CLEAR_RHS_TEMPERATURE');

export const ACTIVATE_SUBSCRIPTION_START = prefix('ACTIVATE_SUBSCRIPTION_START');
export const ACTIVATE_SUBSCRIPTION_SUCCESS = prefix('ACTIVATE_SUBSCRIPTION_SUCCESS');
export const ACTIVATE_SUBSCRIPTION_FAILED = prefix('ACTIVATE_SUBSCRIPTION_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_STATS = prefix('CLEAR_STATS');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
