import { fromJS } from 'immutable';
import * as constants from 'data/constants/monitors';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('monitor');
export const listLoadingProgress = new LoadingProgress('monitorList');
export const itemsPagination = new Pagination('monitorList');

export const availabilityTemperatureLoadingProgress = new LoadingProgress('monitorAvailabilityTemperature');
export const availabilityCoughLoadingProgress = new LoadingProgress('monitorAvailabilityCough');
export const availabilityStatusLoadingProgress = new LoadingProgress('monitorAvailabilityStatus');

export const rhsLoadingProgress = new LoadingProgress('monitorRhs');
export const temperatureLoadingProgress = new LoadingProgress('monitorTemperature');
export const humidityLoadingProgress = new LoadingProgress('monitorHumidity');

export const heatmapTemperatureLoadingProgress = new LoadingProgress('monitorHeatmapTemperature');
export const heatmapCoughLoadingProgress = new LoadingProgress('monitorHeatmapCough');

export const colorHistoryLoadingProgress = new LoadingProgress('monitorColorHistory');
export const colorHistoryItemsPagination = new Pagination('monitorColorHistory');

export const statsLoadingProgress = new LoadingProgress('monitorsStats');

const mergeData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { monitors },
    } = payload;
    newState.mergeIn(['entities'], fromJS(monitors));
  });

const loadList = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const mergeColorHistoryData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { colorHistoryItems },
    } = payload;
    newState.mergeIn(['colorHistory'], fromJS(colorHistoryItems));
  });

const loadColorHistory = (state, action) =>
  state.withMutations(newState => {
    mergeColorHistoryData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    colorHistoryItemsPagination.set(newState, total, perPage, fromJS(result));
    colorHistoryLoadingProgress.setLoaded(newState);
  });

const loadAvailabilityTemperature = (state, action) =>
  state.withMutations(newState => {
    const { payload } = action;
    newState.setIn(['availabilityTemperature'], fromJS(payload));
    availabilityTemperatureLoadingProgress.setLoaded(newState);
  });

const loadAvailabilityCough = (state, action) =>
  state.withMutations(newState => {
    const { payload } = action;
    newState.setIn(['availabilityCough'], fromJS(payload));
    availabilityCoughLoadingProgress.setLoaded(newState);
  });

const loadAvailabilityStatus = (state, action) =>
  state.withMutations(newState => {
    const { payload } = action;
    newState.setIn(['availabilityStatus'], fromJS(payload));
    availabilityStatusLoadingProgress.setLoaded(newState);
  });

const loadRhs = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data, id },
    } = action;
    newState.setIn(['rhs'], fromJS(data));
    newState.setIn(['rhsComplex', id], fromJS(data));
    rhsLoadingProgress.setLoaded(newState);
  });

const loadTemperature = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data, id },
    } = action;
    newState.setIn(['temperature'], fromJS(data));
    newState.setIn(['temperatureComplex', id], fromJS(data));
    temperatureLoadingProgress.setLoaded(newState);
  });

const loadHumidity = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data, id },
    } = action;
    newState.setIn(['humidity'], fromJS(data));
    newState.setIn(['humidityComplex', id], fromJS(data));
    humidityLoadingProgress.setLoaded(newState);
  });

const loadHeatmapCough = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data },
    } = action;
    newState.setIn(['heatmapCough'], fromJS(data));
    heatmapCoughLoadingProgress.setLoaded(newState);
  });

const loadHeatmapTemperature = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data },
    } = action;
    newState.setIn(['heatmapTemperature'], fromJS(data));
    heatmapTemperatureLoadingProgress.setLoaded(newState);
  });

const clearRhs = state =>
  state.withMutations(newState => {
    rhsLoadingProgress.clear(newState);
    newState.setIn(['rhs'], null);
  });

const clearTemperature = state =>
  state.withMutations(newState => {
    temperatureLoadingProgress.clear(newState);
    newState.setIn(['temperature'], null);
  });

const clearRhsTemperature = state =>
  state.withMutations(newState => {
    clearRhs(newState);
    clearTemperature(newState);
  });

const loadStats = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['monitorsStats'], fromJS(action.payload));
    statsLoadingProgress.setLoaded(newState);
  });

const clearStats = state =>
  state.withMutations(newState => {
    statsLoadingProgress.clear(newState);
  });

const clearSingle = state =>
  state.withMutations(newState => {
    loadingProgress.clear(newState);
  });

const clear = state =>
  state.withMutations(newState => {
    listLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);

    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.AVAILABILITY_TEMPERATURE_LOAD_START:
      return availabilityTemperatureLoadingProgress.setLoading(state);
    case constants.AVAILABILITY_TEMPERATURE_LOAD_FAILED:
      return availabilityTemperatureLoadingProgress.setLoadFailed(state);
    case constants.AVAILABILITY_TEMPERATURE_LOAD_SUCCESS:
      return loadAvailabilityTemperature(state, action);

    case constants.AVAILABILITY_COUGH_LOAD_START:
      return availabilityCoughLoadingProgress.setLoading(state);
    case constants.AVAILABILITY_COUGH_LOAD_FAILED:
      return availabilityCoughLoadingProgress.setLoadFailed(state);
    case constants.AVAILABILITY_COUGH_LOAD_SUCCESS:
      return loadAvailabilityCough(state, action);

    case constants.AVAILABILITY_STATUS_LOAD_START:
      return availabilityStatusLoadingProgress.setLoading(state);
    case constants.AVAILABILITY_STATUS_LOAD_FAILED:
      return availabilityStatusLoadingProgress.setLoadFailed(state);
    case constants.AVAILABILITY_STATUS_LOAD_SUCCESS:
      return loadAvailabilityStatus(state, action);

    case constants.RHS_DATA_LOAD_START:
      return rhsLoadingProgress.setLoading(state);
    case constants.RHS_DATA_LOAD_FAILED:
      return rhsLoadingProgress.setLoadFailed(state);
    case constants.RHS_DATA_LOAD_SUCCESS:
      return loadRhs(state, action);

    case constants.TEMPERATURE_DATA_LOAD_START:
      return temperatureLoadingProgress.setLoading(state);
    case constants.TEMPERATURE_DATA_LOAD_FAILED:
      return temperatureLoadingProgress.setLoadFailed(state);
    case constants.TEMPERATURE_DATA_LOAD_SUCCESS:
      return loadTemperature(state, action);

    case constants.HUMIDITY_DATA_LOAD_START:
      return humidityLoadingProgress.setLoading(state);
    case constants.HUMIDITY_DATA_LOAD_FAILED:
      return humidityLoadingProgress.setLoadFailed(state);
    case constants.HUMIDITY_DATA_LOAD_SUCCESS:
      return loadHumidity(state, action);

    case constants.HEATMAP_COUGH_LOAD_START:
      return heatmapTemperatureLoadingProgress.setLoading(state);
    case constants.HEATMAP_COUGH_LOAD_FAILED:
      return heatmapTemperatureLoadingProgress.setLoadFailed(state);
    case constants.HEATMAP_COUGH_LOAD_SUCCESS:
      return loadHeatmapCough(state, action);

    case constants.HEATMAP_TEMPERATURE_LOAD_START:
      return heatmapCoughLoadingProgress.setLoading(state);
    case constants.HEATMAP_TEMPERATURE_LOAD_FAILED:
      return heatmapCoughLoadingProgress.setLoadFailed(state);
    case constants.HEATMAP_TEMPERATURE_LOAD_SUCCESS:
      return loadHeatmapTemperature(state, action);

    case constants.STATS_LOAD_START:
      return statsLoadingProgress.setLoading(state);
    case constants.STATS_LOAD_FAILED:
      return statsLoadingProgress.setLoadFailed(state);
    case constants.STATS_LOAD_SUCCESS:
      return loadStats(state, action);

    case constants.COLOR_HISTORY_LOAD_START:
      return colorHistoryLoadingProgress.setLoading(state);
    case constants.COLOR_HISTORY_LOAD_FAILED:
      return colorHistoryLoadingProgress.setLoadFailed(state);
    case constants.COLOR_HISTORY_LOAD_SUCCESS:
      return loadColorHistory(state, action);

    case constants.CLEAR_RHS_TEMPERATURE:
      return clearRhsTemperature(state, action);

    case constants.CLEAR:
      return clear(state, action);

    case constants.CLEAR_STATS:
      return clearStats(state, action);

    case constants.CLEAR_SINGLE:
      return clearSingle(state);

    default:
      return state;
  }
};
