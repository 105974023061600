import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import * as ImmutablePropTypes from 'react-immutable-proptypes';
import useOutsideClick from 'utils/use-outside-click';
import { profilePath, signOutPath, profileHelpCenterPath } from 'constants/urls';
import { PrimaryButtonLink, ButtonLink } from 'components/core/button';
import { Row, Col } from 'components/core/grid';
import Avatar from 'components/core/avatar';
import Icon from 'components/core/icon';
import { reversedFullName } from 'config';

import s from './styles.scss';

const Profile = ({ profile }) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(null);
  const handleClose = () => setShowDropdown(null);

  const profileDropDownRef = useRef(null);
  const helpDropDownRef = useRef(null);
  const appsDropDownRef = useRef(null);

  useOutsideClick(helpDropDownRef, () => {
    if (showDropdown === 'help') handleClose();
  });

  useOutsideClick(appsDropDownRef, () => {
    if (showDropdown === 'apps') handleClose();
  });

  useOutsideClick(profileDropDownRef, () => {
    if (showDropdown === 'profile') handleClose();
  });

  const firstName = profile.get('first_name');
  const lastName = profile.get('last_name');
  return (
    <div className={s.wrapper} id="user-profile">
      <div className={s.group}>
        <button onClick={() => setShowDropdown('help')} className={s.button} type="button">
          <Icon icon={Icon.icons.helpOutlinedThick} className={s.buttonIcon} />
        </button>

        {showDropdown === 'help' && (
          <div ref={helpDropDownRef} className={s.helpDropdown}>
            <Link to={profileHelpCenterPath} className={s.helpDropdownLink} onClick={handleClose}>
              {t('help_center')}
            </Link>
          </div>
        )}
      </div>

      <div className={s.group}>
        <button data-cy="userProfile" type="button" onClick={() => setShowDropdown('profile')} className={s.user}>
          <div className={s.userName} data-cy="userProfileUsername">
            {reversedFullName ? `${lastName} ${firstName}` : `${firstName} ${lastName}`}
          </div>
          <Avatar
            firstname={firstName}
            lastname={lastName}
            src={profile.get('profile_picture')}
            isReversed={reversedFullName}
            className={s.userAvatar}
          />
          <Icon icon={showDropdown === 'profile' ? Icon.icons.arrowUp : Icon.icons.arrowDown} className={s.userIcon} />
        </button>

        {showDropdown === 'profile' && (
          <div ref={profileDropDownRef} className={s.profileDropdown} data-cy="userProfileDropdown">
            <div className={s.profileDropdownCenter}>
              <Avatar
                firstname={firstName}
                lastname={lastName}
                src={profile.get('profile_picture')}
                isReversed={reversedFullName}
                className={s.profileDropdownAvatar}
              />
              <div className={s.profileDropdownName}>
                {reversedFullName ? `${lastName} ${firstName}` : `${firstName} ${lastName}`}
              </div>
              <div className={s.profileDropdownEmail}>{profile.get('email')}</div>
            </div>
            <Row>
              <Col s={6} data-cy="userProfileLink">
                <PrimaryButtonLink
                  onClick={handleClose}
                  to={profilePath}
                  className={cx(s.profileDropdownButton)}
                  data-cy="userProfileLink"
                >
                  {t('profile')}
                </PrimaryButtonLink>
              </Col>
              <Col s={6} data-cy="userSignOutLink">
                <ButtonLink onClick={handleClose} to={signOutPath} className={s.profileDropdownButton}>
                  {t('log_out')}
                </ButtonLink>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

Profile.propTypes = {
  profile: ImmutablePropTypes.mapContains(PropTypes.shape({})),
};

Profile.defaultProps = {
  profile: null,
};

export default Profile;
