import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DarkInput, PrimaryButton, FieldWrapper } from 'ui';

import { FluidPreloader } from 'components/core/preloader';
import { Trans } from 'react-i18next';

import { resetPasswordPath } from 'constants/urls';
import { Form, Field } from 'formik';

import boehringerLogo from 'public/logo_st.png';
import stLogo from 'public/soundtalks-logo.png';

import HeaderShared from './layout/header';
import s from './styles.scss';

const SignIn = ({ className, children, isSubmitting, errors, ...props }) => (
  <div className={cx(s.sharedForm, className, s.sharedFormSignIn)}>
    <HeaderShared className={s.sharedFormHeader}>
      <div className={s.sharedFormHeaderLogo} style={{ backgroundImage: `url(${boehringerLogo})` }} />
      <div className={s.sharedFormHeaderSt}>
        <div className={s.sharedFormHeaderStTitle}>
          <Trans i18nKey="powered_by" />
        </div>
        <div className={s.sharedFormHeaderStLogo} style={{ backgroundImage: `url(${stLogo})` }} />
      </div>
    </HeaderShared>

    <Form>
      <Field
        id="login"
        name="username"
        placeholder="Email Address"
        component={FieldWrapper}
        inputComponent={DarkInput}
        type="email"
        className={s.sharedFormField}
      />
      <Link to={resetPasswordPath} className={s.sharedFormForgot}>
        Forgot Password?
      </Link>

      <Field
        id="password"
        name="password"
        secureTextEntry
        placeholder="Password"
        component={FieldWrapper}
        inputComponent={DarkInput}
        type="password"
        className={s.sharedFormField}
        error={(errors && (errors.password || errors.message || null)) || null}
      />
      <PrimaryButton type="submit" className={s.sharedFormSubmit} onClick={props.handleSubmit}>
        {isSubmitting ? <FluidPreloader light /> : <Trans i18nKey="login" />}
      </PrimaryButton>
    </Form>
  </div>
);

SignIn.defaultProps = {
  className: null,
  children: null,
  errors: {},
};

SignIn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

export default SignIn;
