import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  itemsPagination,
  statsLoadingProgress,
  statusTimelineLoadingProgress,
} from 'data/reducers/gateways';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.gateways;

export const getEntities = state => getState(state).get('entities');

export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (gateways, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.gateway], new Map({ gateways })),
);

export const getItemsTotal = state => itemsPagination.getTotalItems(getState(state));

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = state => loadingProgress.getLoaded(getState(state));
export const getItemLoading = state => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = state => loadingProgress.getLoadFailed(getState(state));

// Stats

export const getStats = state => getState(state).get('gatewaysStats');

export const getGatewaysStatsLoaded = state => statsLoadingProgress.getLoaded(getState(state));
export const getGatewaysStatsLoading = state => statsLoadingProgress.getLoading(getState(state));
export const getGatewaysStatsLoadFailed = state => statsLoadingProgress.getLoadFailed(getState(state));

// Status Timeline

export const getStatusTimeline = state => getState(state).get('statusTimeline');

export const getStatusTimelineLoaded = state => statusTimelineLoadingProgress.getLoaded(getState(state));
export const getStatusTimelineLoading = state => statusTimelineLoadingProgress.getLoading(getState(state));
export const getStatusTimelineLoadFailed = state => statusTimelineLoadingProgress.getLoadFailed(getState(state));
