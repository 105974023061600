import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { AuthProvider as ContextProvider } from 'data';
import { RouterPropTypes } from 'utils/prop-types';

class AuthProvider extends React.Component {
  state = {
    currentRole: null,
  };

  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    history: RouterPropTypes.history.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  getLocation = () => this.props.location;

  redirect = (to, state) => {
    this.props.history.replace(to, state);
  };

  setCurrentRole = currentRole => {
    this.setState({
      currentRole,
    });
  };

  render() {
    return (
      <ContextProvider
        value={{
          redirect: this.redirect,
          getLocation: this.getLocation,
          setCurrentRole: this.setCurrentRole,
          currentRole: this.state.currentRole,
        }}
      >
        {this.props.children}
      </ContextProvider>
    );
  }
}

export default withRouter(AuthProvider);
