import React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSessionActive } from 'data/selectors/session';

import AuthContext from './context';

const mapStateToProps = state => ({
  sessionActive: getSessionActive(state),
});

const mapDispatchToProps = {};

class AuthorizedComponent extends React.Component {
  static contextType = AuthContext;

  static propTypes = {
    children: PropTypes.node.isRequired,
    redirectTo: PropTypes.string,
    preloader: PropTypes.node,
    sessionActive: PropTypes.bool,
  };

  static defaultProps = {
    sessionActive: null,
    redirectTo: null,
    preloader: null,
  };

  componentDidMount() {}

  render() {
    if (this.props.sessionActive) {
      return this.props.children;
    }
    if (!this.props.sessionActive) {
      if (this.props.redirectTo) {
        this.context.redirect(this.props.redirectTo, { location: this.context.getLocation() });
      }
      return null;
    }

    return this.props.preloader;
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorizedComponent);
