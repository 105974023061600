class Pagination {
  constructor(name) {
    this.totalItemsKey = `${name}TotalItems`;
    this.itemsPerPageKey = `${name}ItemsPerPage`;
    this.pageItemsKey = `${name}PageItems`;
  }

  setTotalItems = (state, value) => state.set(this.totalItemsKey, value);

  getTotalItems = state => state.get(this.totalItemsKey);

  setItemsPerPage = (state, value) => state.set(this.itemsPerPageKey, value);

  getItemsPerPage = state => state.get(this.itemsPerPageKey);

  setPageItems = (state, value) => state.set(this.pageItemsKey, value);

  getPageItems = state => state.get(this.pageItemsKey);

  set = (state, totalItems, itemsPerPage, pageItems) =>
    state.withMutations(myState => {
      this.setTotalItems(myState, totalItems);
      this.setItemsPerPage(myState, itemsPerPage);
      this.setPageItems(myState, pageItems);
    });

  clear = state =>
    state.withMutations(myState => {
      myState.delete(this.totalItemsKey);
      myState.delete(this.itemsPerPageKey);
      myState.delete(this.pageItemsKey);
    });
}

export default Pagination;
