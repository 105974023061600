import * as constants from 'data/constants/replacements';

export const fetchList = (page, perPage, orderingField, order, search, systemId, type, status) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get('replacements/', {
      params: { page, perPage, search, system_id: systemId, type, status, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.replacement]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};

export const fetchReasons = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.REASONS_LOAD_START });
  try {
    const res = await api.get('replacements/reasons/');

    dispatch({
      type: constants.REASONS_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.REASONS_LOAD_FAILED });

    return e;
  }
};

export const fetchSites = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.SITES_LOAD_START });
  try {
    const res = await api.get('replacements/sites/');

    dispatch({
      type: constants.SITES_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.SITES_LOAD_FAILED });

    return e;
  }
};

export const fetchAvailableDevices = siteId => async (dispatch, _, { api }) => {
  dispatch({ type: constants.AVAILABLE_DEVICES_LOAD_START });
  try {
    const res = await api.get(`/replacements/sites/${siteId}/available-devices/`);

    dispatch({
      type: constants.AVAILABLE_DEVICES_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.AVAILABLE_DEVICES_LOAD_FAILED });

    return e;
  }
};

export const fetchDevices = siteId => async (dispatch, _, { api }) => {
  dispatch({ type: constants.DEVICES_LOAD_START });
  try {
    const res = await api.get(`/replacements/sites/${siteId}/devices/`);

    dispatch({
      type: constants.DEVICES_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.DEVICES_LOAD_FAILED });

    return e;
  }
};

export const fetchGateways = siteId => async (dispatch, _, { api }) => {
  dispatch({ type: constants.GATEWAYS_LOAD_START });
  try {
    const res = await api.get(`/replacements/sites/${siteId}/gateways/`);

    dispatch({
      type: constants.GATEWAYS_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.GATEWAYS_LOAD_FAILED });

    return e;
  }
};

export const createRequest = (values, actions) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.REQUEST_CREATION_START });
  try {
    await api.post(`/replacements/`, { ...values });
    actions.setStatus(true);

    dispatch({
      type: constants.REQUEST_CREATION_SUCCESS,
    });
    actions.setSubmitting(false);
    actions.setStatus(true);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.REQUEST_CREATION_FAILED });
    if (e.response && e.response.data) {
      const errors = {};
      if (Array.isArray(e.response.data)) {
        [errors[0]] = e.response.data;
      } else {
        Object.keys(e.response.data).forEach(key => {
          [errors[key]] = e.response.data[key];
        });
      }
      actions.setErrors(errors);
    }
    Object.keys(e.response.data).forEach(key => actions.setFieldTouched(key, true, false));
    actions.setSubmitting(false);
    return false;
  }
};

export const updateReplacement = (replacementId, values, actions) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.REPLACEMENTS_UPDATE_START });

  actions.setSubmitting(true);
  try {
    const res = await api.patch(`/replacements/${replacementId}/`, values);

    actions.setSubmitting(false);
    actions.setStatus(true);
    dispatch({
      type: constants.REPLACEMENTS_UPDATE_SUCCESS,
      payload: normalize(res.data, schema.replacement),
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e.response);
    dispatch({ type: constants.REPLACEMENTS_UPDATE_FAILED });
    if (e.response && e.response.data) {
      const errors = {};
      if (Array.isArray(e.response.data)) {
        [errors.new] = e.response.data;
      } else {
        Object.keys(e.response.data).forEach(key => {
          [errors[key]] = e.response.data[key];
        });
      }
      actions.setErrors(errors);
    }
    actions.setSubmitting(false);

    return false;
  }
};

export const cancelReplacement = replacementId => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.REPLACEMENTS_CANCEL_START });

  try {
    const res = await api.patch(`/replacements/${replacementId}/`, { status: 'CANCELLED' });
    dispatch({
      type: constants.REPLACEMENTS_CANCEL_SUCCESS,
      payload: normalize(res.data, schema.replacement),
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e.response && e.response.data);
    dispatch({ type: constants.REPLACEMENTS_CANCEL_FAILED });

    return false;
  }
};
