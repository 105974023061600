import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { withAuth } from 'data';
import { basePath, baseAppPath, signInPath, notFoundPath } from 'constants/urls';
import { FluidPreloader } from 'components/core/preloader';
import ApiError from 'components/core/api-error';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useNetworkStatus from 'utils/use-network-status';

import AppShared from 'containers/shared';

const AppRoot = loadable(() => import('containers/app'), {
  fallback: <FluidPreloader style={{ height: '100vh' }} />,
});

const AuthorizedAppRoute = withAuth({ redirectTo: signInPath })(AppRoot);

const OPU = () => {
  useNetworkStatus();
  return (
    <React.Fragment>
      <ToastContainer />
      <Switch>
        <Route path={baseAppPath} component={AuthorizedAppRoute} />
        <Route path={notFoundPath} component={ApiError} />
        <Route path={basePath} component={AppShared} />
      </Switch>
    </React.Fragment>
  );
};

export default hot(OPU);
