import * as constants from 'data/constants/airspaces';
import * as monitorConstants from 'data/constants/monitors';

export const fetchRhs = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.RHS_DATA_LOAD_START });

  try {
    const { from, to } = timeframe;
    const res = await api.get(`airspaces/${id}/data/rhs/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.RHS_DATA_LOAD_SUCCESS,
      payload: { data: res.data },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.RHS_DATA_LOAD_FAILED });
  }
};

export const fetchTemperature = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.TEMPERATURE_DATA_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`airspaces/${id}/data/temperature/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.TEMPERATURE_DATA_LOAD_SUCCESS,
      payload: { data: res.data },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.TEMPERATURE_DATA_LOAD_FAILED });
  }
};

export const fetchHumidity = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.HUMIDITY_DATA_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`airspaces/${id}/data/humidity/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.HUMIDITY_DATA_LOAD_SUCCESS,
      payload: { data: res.data },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.HUMIDITY_DATA_LOAD_FAILED });
  }
};

export const fetchMonitorsList = (id, page, perPage, search, online, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.MONITORS_LIST_LOAD_START });
  try {
    const res = await api.get(`airspaces/${id}/devices/`, {
      params: { page, perPage, search, online, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.monitor]),
    };

    dispatch({
      type: constants.MONITORS_LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.MONITORS_LIST_LOAD_FAILED });

    return e;
  }
};

export const fetchMonitorsRhs = (monitors, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.MONITORS_RHS_DATA_LOAD_START });

  try {
    const requests = monitors.map(async monitor => {
      dispatch({ type: monitorConstants.RHS_DATA_LOAD_START });

      try {
        const { id } = monitor;
        const { from, to } = timeframe;
        const res = await api.get(`devices/${id}/data/rhs/`, {
          params: { start_date: from, end_date: to },
        });

        dispatch({
          type: monitorConstants.RHS_DATA_LOAD_SUCCESS,
          payload: { data: res.data, id },
        });
        return true;
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
        dispatch({ type: monitorConstants.RHS_DATA_LOAD_FAILED });
        return false;
      }
    });

    const promises = await Promise.all(requests);
    dispatch({
      type: constants.MONITORS_RHS_DATA_LOAD_SUCCESS,
    });

    promises.map(res => {
      if (!res) throw new Error('Error');
      return true;
    });

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.MONITORS_RHS_DATA_LOAD_FAILED });

    return e;
  }
};

export const fetchMonitorsTemperature = (monitors, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.MONITORS_TEMPERATURE_DATA_LOAD_START });

  try {
    const requests = monitors.map(async monitor => {
      dispatch({ type: monitorConstants.TEMPERATURE_DATA_LOAD_START });

      try {
        const { id } = monitor;
        const { from, to } = timeframe;
        const res = await api.get(`devices/${id}/data/temperature/`, {
          params: { start_date: from, end_date: to },
        });

        dispatch({
          type: monitorConstants.TEMPERATURE_DATA_LOAD_SUCCESS,
          payload: { data: res.data, id },
        });

        return true;
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
        dispatch({ type: monitorConstants.TEMPERATURE_DATA_LOAD_FAILED });

        return e;
      }
    });

    const promises = await Promise.all(requests);
    dispatch({
      type: constants.MONITORS_TEMPERATURE_DATA_LOAD_SUCCESS,
    });

    promises.map(res => {
      if (!res) throw new Error('Error');
      return true;
    });

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.MONITORS_TEMPERATURE_DATA_LOAD_FAILED });

    return e;
  }
};

export const fetchMonitorsHumidity = (monitors, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.MONITORS_HUMIDITY_DATA_LOAD_START });

  try {
    const requests = monitors.map(async monitor => {
      dispatch({ type: monitorConstants.HUMIDITY_DATA_LOAD_START });

      try {
        const { id } = monitor;
        const { from, to } = timeframe;
        const res = await api.get(`devices/${id}/data/humidity/`, {
          params: { start_date: from, end_date: to },
        });

        dispatch({
          type: monitorConstants.HUMIDITY_DATA_LOAD_SUCCESS,
          payload: { data: res.data, id },
        });

        return true;
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
        dispatch({ type: monitorConstants.HUMIDITY_DATA_LOAD_FAILED });

        return e;
      }
    });

    const promises = await Promise.all(requests);
    dispatch({
      type: constants.MONITORS_HUMIDITY_DATA_LOAD_SUCCESS,
    });

    promises.map(res => {
      if (!res) throw new Error('Error');
      return true;
    });

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.MONITORS_HUMIDITY_DATA_LOAD_FAILED });

    return e;
  }
};

export const fetchAllMonitorsHeatmapData = (id, airspaces, sensor, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.AIRSPACE_HEATMAP_LOAD_START });

  try {
    const { from, to } = timeframe;
    const requests = airspaces.map(async airspace => {
      dispatch({ type: constants.MONITORS_HEATMAP_LOAD_START });

      try {
        const res = await api.get(`sites/${id}/airspaces/${airspace}/heatmaps/`, {
          params: { sensor, start_date: from, end_date: to },
        });
        const payload = {
          id: airspace,
          data: res.data,
        };
        dispatch({
          type: constants.MONITORS_HEATMAP_LOAD_SUCCESS,
          payload,
        });
        return true;
      } catch (e) {
        dispatch({ type: constants.MONITORS_HEATMAP_LOAD_FAILED });
        return false;
      }
    });
    const promises = await Promise.all(requests);
    dispatch({ type: constants.AIRSPACE_HEATMAP_LOAD_SUCCESS });

    promises.map(res => {
      if (!res) throw new Error('Error');
      return true;
    });

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.AIRSPACE_HEATMAP_LOAD_FAILED });
    return false;
  }
};
