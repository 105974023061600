import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

export const WebButton = ({ className, children, isDisabled, ...props }) => (
  <button type="button" disabled={isDisabled} className={cx(s.buttonWeb, className)} {...props}>
    <span className={s.buttonWebText}>{children}</span>
  </button>
);

WebButton.propTypes = {
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

WebButton.defaultProps = {
  isDisabled: false,
  className: null,
  children: null,
};

export const WebPrimaryButton = ({ className, children, isDisabled, ...props }) => (
  <button type="button" disabled={isDisabled} className={cx(s.buttonWeb, s.buttonWebPrimary, className)} {...props}>
    <span className={s.buttonWebText}>{children}</span>
  </button>
);

WebPrimaryButton.propTypes = {
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

WebPrimaryButton.defaultProps = {
  isDisabled: false,
  className: null,
  children: null,
};
