import { fromJS } from 'immutable';
import * as constants from 'data/constants/airspaces';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const rhsLoadingProgress = new LoadingProgress('rhs');
export const temperatureLoadingProgress = new LoadingProgress('temperature');
export const humidityLoadingProgress = new LoadingProgress('humidity');

export const monitorsListLoadingProgress = new LoadingProgress('airspaceMonitors');
export const monitorsItemsPagination = new Pagination('airspaceMonitors');

export const monitorsRhsLoadingProgress = new LoadingProgress('airspaceMonitorsRhs');
export const monitorsTemperatureLoadingProgress = new LoadingProgress('airspaceMonitorsTemperature');
export const monitorsHumidityLoadingProgress = new LoadingProgress('airspaceMonitorsHumidity');

export const monitorsHeatmapLoadingProgress = new LoadingProgress('airspaceMonitorsHeatmap');

const loadRhs = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data },
    } = action;
    newState.setIn(['rhs'], fromJS(data));
    rhsLoadingProgress.setLoaded(newState);
  });

const loadTemperature = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data },
    } = action;
    newState.setIn(['temperature'], fromJS(data));
    temperatureLoadingProgress.setLoaded(newState);
  });

const loadHumidity = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data },
    } = action;
    newState.setIn(['humidity'], fromJS(data));
    humidityLoadingProgress.setLoaded(newState);
  });

const mergeMonitorData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { monitors },
    } = payload;
    newState.mergeIn(['monitors'], fromJS(monitors));
  });

const loadMonitorsList = (state, action) =>
  state.withMutations(newState => {
    mergeMonitorData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    monitorsItemsPagination.set(newState, total, perPage, fromJS(result));
    monitorsListLoadingProgress.setLoaded(newState);
  });

const loadDevicesHeatmap = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data, id },
    } = action;

    newState.setIn(['monitorsHeatmap', id], fromJS(data));
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.RHS_DATA_LOAD_START:
      return rhsLoadingProgress.setLoading(state);
    case constants.RHS_DATA_LOAD_FAILED:
      return rhsLoadingProgress.setLoadFailed(state);
    case constants.RHS_DATA_LOAD_SUCCESS:
      return loadRhs(state, action);

    case constants.TEMPERATURE_DATA_LOAD_START:
      return temperatureLoadingProgress.setLoading(state);
    case constants.TEMPERATURE_DATA_LOAD_FAILED:
      return temperatureLoadingProgress.setLoadFailed(state);
    case constants.TEMPERATURE_DATA_LOAD_SUCCESS:
      return loadTemperature(state, action);

    case constants.HUMIDITY_DATA_LOAD_START:
      return humidityLoadingProgress.setLoading(state);
    case constants.HUMIDITY_DATA_LOAD_FAILED:
      return humidityLoadingProgress.setLoadFailed(state);
    case constants.HUMIDITY_DATA_LOAD_SUCCESS:
      return loadHumidity(state, action);

    case constants.MONITORS_LIST_LOAD_START:
      return monitorsListLoadingProgress.setLoading(state);
    case constants.MONITORS_LIST_LOAD_FAILED:
      return monitorsListLoadingProgress.setLoadFailed(state);
    case constants.MONITORS_LIST_LOAD_SUCCESS:
      return loadMonitorsList(state, action);

    case constants.MONITORS_RHS_DATA_LOAD_START:
      return monitorsRhsLoadingProgress.setLoading(state);
    case constants.MONITORS_RHS_DATA_LOAD_FAILED:
      return monitorsRhsLoadingProgress.setLoadFailed(state);
    case constants.MONITORS_RHS_DATA_LOAD_SUCCESS:
      return monitorsRhsLoadingProgress.setLoaded(state);

    case constants.MONITORS_TEMPERATURE_DATA_LOAD_START:
      return monitorsTemperatureLoadingProgress.setLoading(state);
    case constants.MONITORS_TEMPERATURE_DATA_LOAD_FAILED:
      return monitorsTemperatureLoadingProgress.setLoadFailed(state);
    case constants.MONITORS_TEMPERATURE_DATA_LOAD_SUCCESS:
      return monitorsTemperatureLoadingProgress.setLoaded(state);

    case constants.MONITORS_HUMIDITY_DATA_LOAD_START:
      return monitorsHumidityLoadingProgress.setLoading(state);
    case constants.MONITORS_HUMIDITY_DATA_LOAD_FAILED:
      return monitorsHumidityLoadingProgress.setLoadFailed(state);
    case constants.MONITORS_HUMIDITY_DATA_LOAD_SUCCESS:
      return monitorsHumidityLoadingProgress.setLoaded(state);

    case constants.MONITORS_HEATMAP_LOAD_SUCCESS:
      return loadDevicesHeatmap(state, action);

    case constants.AIRSPACE_HEATMAP_LOAD_START:
      return monitorsHeatmapLoadingProgress.setLoading(state);
    case constants.AIRSPACE_HEATMAP_LOAD_FAILED:
      return monitorsHeatmapLoadingProgress.setLoadFailed(state);
    case constants.AIRSPACE_HEATMAP_LOAD_SUCCESS:
      return monitorsHeatmapLoadingProgress.setLoaded(state);

    default:
      return state;
  }
};
