import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';

// eslint-disable-next-line
import { Provider, createStore, actions, ApiInterceptor } from 'data';
import storage from 'utils/storage';
import { FluidPreloader } from 'components/core/preloader';

import { PersistGate } from 'redux-persist/integration/react';

import AuthProvider from 'utils/authorization-provider';

import 'ui/styles/global.scss';

import OPU from 'containers';

import i18n from 'i18next';
import history from './history';

import './i18n';

const rootElement = document.getElementById('root');

const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

const { store, persistor } = createStore({ storage, language: getLanguage() });

const onBeforeLift = async () => {
  await store.dispatch(actions.session.refreshSession());
  return true;
};

class Application extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
          <ApiInterceptor history={history}>
            <Router history={history}>
              <AuthProvider>
                <Suspense fallback={<FluidPreloader style={{ height: '100vh' }} />}>
                  <OPU />
                </Suspense>
              </AuthProvider>
            </Router>
          </ApiInterceptor>
        </PersistGate>
      </Provider>
    );
  }
}

render(<Application />, rootElement);
