import React from 'react';
import AuthorizedComponent from './authorized';

const withAuth = ({ redirectTo = false, preloader = null }) => Component => props => (
  <AuthorizedComponent redirectTo={redirectTo} preloader={preloader}>
    <Component {...props} />
  </AuthorizedComponent>
);

export default withAuth;
