import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import createBreadcrumbs from 'utils/breadcrumbs';

import s from './styles.scss';

const { Breadcrumbs: BreadcrumbsComponent, Provider, Anchor } = createBreadcrumbs();

const Container = ({ className, children }) => <div className={cx(s.breadcrumbs, className)}>{children}</div>;

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Container.defaultProps = {
  className: null,
  children: null,
};

const Item = ({ item }) => (
  <Link to={item.link} className={s.breadcrumbsItem} style={item.wrapperStyle}>
    {item.component}
  </Link>
);

Item.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

const Divider = () => <div className={s.breadcrumbsDivider}>&gt;</div>;

const Breadcrumbs = ({ ...props }) => (
  <BreadcrumbsComponent
    {...props}
    components={{
      Item,
      Divider,
      Container,
    }}
  />
);

Breadcrumbs.Provider = Provider;

Breadcrumbs.Anchor = Anchor;

export default Breadcrumbs;
