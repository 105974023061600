const prefix = type => `opu/users/${type}`;

export const ADD_START = prefix('ADD_START');
export const ADD_SUCCESS = prefix('ADD_SUCCESS');
export const ADD_FAILED = prefix('ADD_FAILED');

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LIST_DOWNLOAD_START = prefix('LIST_DOWNLOAD_START');
export const LIST_DOWNLOAD_SUCCESS = prefix('LIST_DOWNLOAD_SUCCESS');
export const LIST_DOWNLOAD_FAILED = prefix('LIST_DOWNLOAD_FAILED');

export const SUPPLIERS_LIST_LOAD_START = prefix('SUPPLIERS_LIST_LOAD_START');
export const SUPPLIERS_LIST_LOAD_SUCCESS = prefix('SUPPLIERS_LIST_LOAD_SUCCESS');
export const SUPPLIERS_LIST_LOAD_FAILED = prefix('SUPPLIERS_LIST_LOAD_FAILED');

export const ROLES_LIST_LOAD_START = prefix('ROLES_LIST_LOAD_START');
export const ROLES_LIST_LOAD_SUCCESS = prefix('ROLES_LIST_LOAD_SUCCESS');
export const ROLES_LIST_LOAD_FAILED = prefix('ROLES_LIST_LOAD_FAILED');

export const ROLE_EDIT_START = prefix('ROLE_EDIT_START');
export const ROLE_EDIT_SUCCESS = prefix('ROLE_EDIT_SUCCESS');
export const ROLE_EDIT_FAILED = prefix('ROLE_EDIT_FAILED');

export const ROLES_LOAD_START = prefix('ROLES_LOAD_START');
export const ROLES_LOAD_SUCCESS = prefix('ROLES_LOAD_SUCCESS');
export const ROLES_LOAD_FAILED = prefix('ROLES_LOAD_FAILED');

export const REGISTER_START = prefix('REGISTER_START');
export const REGISTER_SUCCESS = prefix('REGISTER_SUCCESS');
export const REGISTER_FAILED = prefix('REGISTER_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
