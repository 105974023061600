export default class Storage {
  static async getItem(key) {
    return Promise.resolve(window.localStorage.getItem(key));
  }

  static async setItem(key, value) {
    return Promise.resolve(window.localStorage.setItem(key, value));
  }

  static async removeItem(key) {
    return Promise.resolve(window.localStorage.removeItem(key));
  }

  static async clear() {
    return Promise.resolve(window.localStorage.clear());
  }
}
