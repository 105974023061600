import axios from 'axios';
import { externalBaseUrl } from 'config';
// eslint-disable-next-line
import jwt_decode from 'jwt-decode';
import { HTTPError /* , ConnectionError */ } from './utils/errors';
import * as apiConstants from './constants/api';
import * as sessionConstants from './constants/session';
import * as sessionSelectors from './selectors/session';

class API {
  constructor(store, ...axiosArgs) {
    this.refreshInApi = this.refreshInApi.bind(this);
    this.errorInterceptor = this.errorInterceptor.bind(this);
    this.successInterceptor = this.successInterceptor.bind(this);
    this.setAuthorization = this.setAuthorization.bind(this);
    this.removeAuthorization = this.removeAuthorization.bind(this);
    this.requestInterceptor = this.requestInterceptor.bind(this);

    this.axios = axios.create(...axiosArgs);
    this.store = store;
    this.axios.interceptors.response.use(this.successInterceptor, this.errorInterceptor);
    this.axios.interceptors.request.use(this.requestInterceptor, null);
  }

  static noRefresh = 'No token';

  static isRefreshUrl(url) {
    return new RegExp(/\/api\/token\/refresh/).test(url);
  }

  static isAuthUrl(url) {
    return new RegExp(/\/api\/token/).test(url);
  }

  async refreshInApi() {
    const refresh = sessionSelectors.getRefresh(this.store.getState());
    if (!refresh) throw new Error(API.noRefresh);
    const res = await axios.post(`${externalBaseUrl}/api/token/refresh/`, { refresh });
    this.setAuthorization(res.data.access);
    this.store.dispatch({
      type: sessionConstants.REFRESH_SUCCESS,
      payload: {
        ...res.data,
      },
    });

    return res.data;
  }

  successInterceptor(response) {
    this.store.dispatch({ type: apiConstants.SUCCESS });
    return response;
  }

  requestInterceptor(request) {
    const oldAccess = sessionSelectors.getAccess(this.store.getState());

    if (oldAccess && request.url !== '/api/token/refresh/') {
      const { exp } = jwt_decode(oldAccess);
      if (Date.now() >= exp * 1000) {
        return this.refreshInApi().then(({ access }) => {
          request.headers.common.Authorization = `Bearer ${access}`;
          return request;
        });
      }
    }

    return request;
  }

  errorInterceptor(error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          if (error.config) {
            const reqConfig = error.config;
            if (reqConfig.url === `${reqConfig.baseURL}/api/token/refresh/`) {
              this.store.dispatch({
                type: sessionConstants.LOGOUT,
              });
            }
          }
          break;
        case 401:
          if (error.config) {
            const reqConfig = error.config;
            if (reqConfig.url === `${reqConfig.baseURL}/api/token/refresh/`) {
              this.store.dispatch({
                type: sessionConstants.LOGOUT,
              });
            }
            if (!API.isAuthUrl(reqConfig.url)) {
              return this.refreshInApi().then(({ access }) => {
                reqConfig.headers.Authorization = `Bearer ${access}`;
                return this.axios.request(reqConfig);
              });
            }

            // .catch(e => {
            //   if (e.message === API.noRefresh) {
            //     this.store.dispatch({
            //       type: sessionConstants.LOGOUT,
            //     });
            //   }
            // })
          }

          this.store.dispatch({ type: apiConstants.UNAUTHORIZED });
          // throw new HTTPError('Unauthorized', error.response.status);
          break;
        case 500:
          this.store.dispatch({ type: apiConstants.SERVER_ERROR });
          throw new HTTPError('Server Error', error.response.status);
        case 404:
          this.store.dispatch({ type: apiConstants.NOT_FOUND });
          throw new HTTPError('Not Found', error.response.status);
        case 403:
          this.store.dispatch({ type: apiConstants.FORBIDDEN });
          throw new HTTPError('Forbidden', error.response.status);
        default:
          break;
      }
    } else if (!navigator.onLine) {
      this.store.dispatch({ type: apiConstants.CONNECTION_ERROR });

      // throw new ConnectionError('No connection');
      // if (error.config) {
      //   const reqConfig = error.config;
      //
      //   console.log('Connection error, will retry in 3 sec');
      //   return wait(3000).then(() => this.axios.request(reqConfig));
      // }
    }
    return Promise.reject(error);
  }

  setAuthorization(apiKey) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${apiKey}`;
  }

  setLanguage(language) {
    this.axios.defaults.headers['Accept-Language'] = language;
  }

  removeAuthorization() {
    delete this.axios.defaults.headers.common.Authorization;
  }

  get = (...args) => this.axios.get.apply(this, args);

  post = (...args) => this.axios.post.apply(this, args);

  patch = (...args) => this.axios.patch.apply(this, args);

  put = (...args) => this.axios.put.apply(this, args);

  delete = (...args) => this.axios.delete.apply(this, args);
}

export default API;
