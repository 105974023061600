import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  itemsPagination,
  statusLoadingProgress,
  monitorsLoadingProgress,
  monitorsListPagination,
  gatewaysLoadingProgress,
  gatewaysListPagination,
  statsLoadingProgress,
  alertsLoadingProgress,
  alertsListPagination,
  notificationsLoadingProgress,
  notificationsListPagination,
  pushNotificationsLoadingProgress,
  pushNotificationsListPagination,
  airspacesLoadingProgress,
  airspacesListPagination,
  heatmapLoadingProgress,
  mapListLoadingProgress,
  devicesAvailabilityLoadingProgress,
  systemsLoadingProgress,
  diseaseHistoryLoadingProgress,
} from 'data/reducers/sites';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.sites;

export const getEntities = state => getState(state).get('entities');

export const getStatus = state => getState(state).get('status');

export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (sites, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.site], new Map({ sites })),
);

export const getItemsTotal = state => itemsPagination.getTotalItems(getState(state));

export const getMonitors = state => getState(state).get('monitors');

export const getMonitorsList = createImmutableEqualSelector(getMonitors, getState, (monitors, state) =>
  denormalize(monitorsListPagination.getPageItems(state), [schemas.monitor], new Map({ monitors })),
);

export const getStats = state => getState(state).get('sitesStats');

export const getGateways = state => getState(state).get('gateways');

export const getGatewaysList = createImmutableEqualSelector(getGateways, getState, (gateways, state) =>
  denormalize(gatewaysListPagination.getPageItems(state), [schemas.gateway], new Map({ gateways })),
);

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = state => loadingProgress.getLoaded(getState(state));
export const getItemLoading = state => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = state => loadingProgress.getLoadFailed(getState(state));

export const getStatusLoaded = state => statusLoadingProgress.getLoaded(getState(state));
export const getStatusLoading = state => statusLoadingProgress.getLoading(getState(state));
export const getStatusLoadFailed = state => statusLoadingProgress.getLoadFailed(getState(state));

export const getMonitorListLoaded = state => monitorsLoadingProgress.getLoaded(getState(state));
export const getMonitorListLoading = state => monitorsLoadingProgress.getLoading(getState(state));
export const getMonitorListLoadFailed = state => monitorsLoadingProgress.getLoadFailed(getState(state));

export const getGatewayListLoaded = state => gatewaysLoadingProgress.getLoaded(getState(state));
export const getGatewayListLoading = state => gatewaysLoadingProgress.getLoading(getState(state));
export const getGatewayListLoadFailed = state => gatewaysLoadingProgress.getLoadFailed(getState(state));

export const getSiteStatsLoaded = state => statsLoadingProgress.getLoaded(getState(state));
export const getSiteStatsLoading = state => statsLoadingProgress.getLoading(getState(state));
export const getSiteStatsLoadFailed = state => statsLoadingProgress.getLoadFailed(getState(state));

// Site alerts
export const getAlerts = state => getState(state).get('alerts');

export const getAlertsList = createImmutableEqualSelector(getAlerts, getState, (alerts, state) =>
  denormalize(alertsListPagination.getPageItems(state), [schemas.alert], new Map({ alerts })),
);

export const getAlertsListTotal = state => alertsListPagination.getTotalItems(getState(state));

export const getAlertsListLoaded = state => alertsLoadingProgress.getLoaded(getState(state));
export const getAlertsListLoading = state => alertsLoadingProgress.getLoading(getState(state));
export const getAlertsListLoadFailed = state => alertsLoadingProgress.getLoadFailed(getState(state));

// Site notifications
export const getNotifications = state => getState(state).get('notifications');

export const getNotificationsList = createImmutableEqualSelector(getNotifications, getState, (notifications, state) =>
  denormalize(notificationsListPagination.getPageItems(state), [schemas.notification], new Map({ notifications })),
);

export const getNotificationsListTotal = state => notificationsListPagination.getTotalItems(getState(state));

export const getNotificationsListLoaded = state => notificationsLoadingProgress.getLoaded(getState(state));
export const getNotificationsListLoading = state => notificationsLoadingProgress.getLoading(getState(state));
export const getNotificationsListLoadFailed = state => notificationsLoadingProgress.getLoadFailed(getState(state));

// Site push notifications
export const getPushNotifications = state => getState(state).get('pushNotifications');

export const getPushNotificationsList = createImmutableEqualSelector(
  getPushNotifications,
  getState,
  (pushNotifications, state) =>
    denormalize(
      pushNotificationsListPagination.getPageItems(state),
      [schemas.pushNotification],
      new Map({ pushNotifications }),
    ),
);

export const getPushNotificationsListTotal = state => pushNotificationsListPagination.getTotalItems(getState(state));

export const getPushNotificationsListLoaded = state => pushNotificationsLoadingProgress.getLoaded(getState(state));
export const getPushNotificationsListLoading = state => pushNotificationsLoadingProgress.getLoading(getState(state));
export const getPushNotificationsListLoadFailed = state =>
  pushNotificationsLoadingProgress.getLoadFailed(getState(state));

// Site airspaces

export const getAirspaces = state => getState(state).get('airspaces');

export const getAirspacesList = createImmutableEqualSelector(getAirspaces, getState, (airspaces, state) =>
  denormalize(airspacesListPagination.getPageItems(state), [schemas.airspace], new Map({ airspaces })),
);

export const getAirspacesListTotal = state => airspacesListPagination.getTotalItems(getState(state));

export const getAirspacesListLoaded = state => airspacesLoadingProgress.getLoaded(getState(state));
export const getAirspacesListLoading = state => airspacesLoadingProgress.getLoading(getState(state));
export const getAirspacesListLoadFailed = state => airspacesLoadingProgress.getLoadFailed(getState(state));

// Site heatmap

export const getHeatmap = state => getState(state).get('heatmap');

export const getHeatmapLoaded = state => heatmapLoadingProgress.getLoaded(getState(state));
export const getHeatmapLoading = state => heatmapLoadingProgress.getLoading(getState(state));
export const getHeatmapLoadFailed = state => heatmapLoadingProgress.getLoadFailed(getState(state));

// Sites map list

export const getItemsMapList = state => getState(state).get('sitesMap');

export const getItemsMapListLoaded = state => mapListLoadingProgress.getLoaded(getState(state));
export const getItemsMapListLoading = state => mapListLoadingProgress.getLoading(getState(state));
export const getItemsMapListLoadFailed = state => mapListLoadingProgress.getLoadFailed(getState(state));

export const getFilter = state => getState(state).get('filter');

export const getStatusFilter = state => getFilter(state).get('status');
export const getTypeFilter = state => getFilter(state).get('type');

export const getFilteredList = state => {
  const status = getStatusFilter(state);
  const type = getTypeFilter(state);

  const itemsLists = getItemsMapList(state);

  if (status.size === 0 && !type.size) return itemsLists;

  const filteredEntities = itemsLists.filter(site => {
    const statusMatches = status.size !== 0 ? status.has(site.get('status')) : true;

    const typeMatches = type.size ? type.has(site.get('type').toLowerCase()) : true;

    return statusMatches && typeMatches;
  });

  return filteredEntities;
};

// Devices availability

export const getDevicesAvailability = state => getState(state).get('devicesAvailability');

export const getDevicesAvailabilityLoaded = state => devicesAvailabilityLoadingProgress.getLoaded(getState(state));
export const getDevicesAvailabilityLoading = state => devicesAvailabilityLoadingProgress.getLoading(getState(state));
export const getDevicesAvailabilityLoadFailed = state =>
  devicesAvailabilityLoadingProgress.getLoadFailed(getState(state));

// site systems
export const getSystems = state => getState(state).get('systems');
export const getSystemsLoaded = state => systemsLoadingProgress.getLoaded(getState(state));
export const getSystemsLoading = state => systemsLoadingProgress.getLoading(getState(state));
export const getSystemsLoadFailed = state => systemsLoadingProgress.getLoadFailed(getState(state));
export const getSystemsList = createImmutableEqualSelector(getSystems, getState, (systems, state) =>
  denormalize(state.get('systemsList'), [schemas.system], new Map({ systems })),
);

export const getDiseaseHistory = state => getState(state).get('diseaseHistory');
export const getDiseaseHistoryLoaded = state => diseaseHistoryLoadingProgress.getLoaded(getState(state));
export const getDiseaseHistoryLoading = state => diseaseHistoryLoadingProgress.getLoading(getState(state));
export const getDiseaseHistoryLoadFailed = state => diseaseHistoryLoadingProgress.getLoadFailed(getState(state));
