const prefix = type => `opu/subscriptions/${type}`;

export const VALIDATE_START = prefix('VALIDATE_START');
export const VALIDATE_SUCCESS = prefix('VALIDATE_SUCCESS');
export const VALIDATE_FAILED = prefix('VALIDATE_FAILED');
export const VALIDATE_CLEAR = prefix('VALIDATE_CLEAR');

export const EXECUTE_START = prefix('EXECUTE_START');
export const EXECUTE_SUCCESS = prefix('EXECUTE_SUCCESS');
export const EXECUTE_FAILED = prefix('EXECUTE_FAILED');
export const EXECUTE_CLEAR = prefix('EXECUTE_CLEAR');
