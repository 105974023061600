import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import { listLoadingProgress, itemsPagination } from 'data/reducers/exports';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.exportsReducer;

export const getEntities = state => getState(state).get('entities');

export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (exportItems, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.exportItem], new Map({ exportItems })),
);

export const getItemsListTotal = state => itemsPagination.getTotalItems(getState(state));

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));
