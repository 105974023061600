import React from 'react';
import { RouterPropTypes } from 'utils/prop-types';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PrimaryButton } from 'ui';

import { signInPath } from 'constants/urls';

import { Trans } from 'react-i18next';

import boehringerLogo from 'public/logo_st.png';
import stLogo from 'public/soundtalks-logo.png';

import HeaderShared from './layout/header';

import SubTitleShared from './layout/subtitle';

import s from './styles.scss';

const ResetPasswordSuccess = ({ className, history }) => (
  <div className={cx(s.sharedForm, className, s.sharedFormSignIn)}>
    <HeaderShared className={s.sharedFormHeader}>
      <div className={s.sharedFormHeaderLogo} style={{ backgroundImage: `url(${boehringerLogo})` }} />
      <div className={s.sharedFormHeaderSt}>
        <div className={s.sharedFormHeaderStTitle}>
          <Trans i18nKey="powered_by" />
        </div>
        <div className={s.sharedFormHeaderStLogo} style={{ backgroundImage: `url(${stLogo})` }} />
      </div>
    </HeaderShared>

    <SubTitleShared className={s.sharedFormSubtitle}>
      <Trans i18nKey="reset_password_success_info" />
    </SubTitleShared>

    <PrimaryButton
      type="submit"
      data-cy="submit"
      className={s.sharedFormSubmit}
      onClick={() => history.push(signInPath)}
    >
      <Trans i18nKey="go_to_login" />
    </PrimaryButton>
  </div>
);

ResetPasswordSuccess.defaultProps = {
  className: null,
};

ResetPasswordSuccess.propTypes = {
  className: PropTypes.string,
  history: RouterPropTypes.history.isRequired,
};

export default ResetPasswordSuccess;
