import { schema } from 'normalizr';

export const monitor = new schema.Entity('monitors');

export const gateway = new schema.Entity('gateways');

export const site = new schema.Entity('sites');

export const system = new schema.Entity('systems');

export const notification = new schema.Entity('notifications');

export const alert = new schema.Entity('alerts');

export const pushNotification = new schema.Entity('pushNotifications');

export const airspace = new schema.Entity('airspaces');

export const colorHistoryItem = new schema.Entity('colorHistoryItems');

export const user = new schema.Entity('users');

export const exportItem = new schema.Entity('exportItems');

export const replacement = new schema.Entity('replacements');

export const supplier = new schema.Entity('suppliers');

export const episode = new schema.Entity('episodes');

export const episodeDay = new schema.Entity('episodeDays');
