import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';

import { connect } from 'react-redux';
import { actions, selectors } from 'data';

import { withTranslation } from 'react-i18next';
import { WithTranslateFormErrors } from 'utils/translation-validation';

import { baseAppPath } from 'constants/urls';

import { Formik } from 'formik';
import SignInForm from 'components/shared/sign-in';

import { LoginSchema } from 'utils/validation';

const mapStateToProps = state => ({ sessionIsActive: selectors.session.getSessionActive(state) });

const mapDispatchToProps = {
  login: actions.session.fetchSession,
};

class SignIn extends React.Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    sessionIsActive: PropTypes.bool,
    location: RouterPropTypes.location.isRequired,
  };

  static defaultProps = { sessionIsActive: false };

  render() {
    if (this.props.sessionIsActive)
      return <Redirect to={this.props.location.state ? this.props.location.state.location.pathname : baseAppPath} />;
    return (
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={this.props.login}
        validationSchema={LoginSchema(this.props.t)}
        render={formikProps => (
          <WithTranslateFormErrors {...formikProps}>
            <SignInForm {...formikProps} />
          </WithTranslateFormErrors>
        )}
      />
    );
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SignIn),
);
