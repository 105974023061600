export const fileTypes = {
  csv: 'text/csv',
  excel: 'data:application/vnd.ms-excel;',
};

export const fileNames = name => ({
  csv: `${name}.csv`,
  excel: `${name}.xlsx`,
});

export const saveBlob = (data, fileName, type) => {
  const blob = new Blob([data], { type: fileTypes[type] });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileNames(fileName)[type];
  link.click();
};

const exportFileNames = name => ({
  csv: `${name}.zip`,
  excel: `${name}.xlsx`,
});

export const saveGeneralBlob = (blob, fileName, type) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportFileNames(fileName)[type]);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = exportFileNames(fileName)[type];
    link.click();
  }
};
