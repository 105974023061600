const prefix = type => `opu/session/${type}`;

export const LOGIN = prefix('LOGIN');
export const LOGIN_SUCCESS = prefix('LOGIN_SUCCESS');
export const LOGIN_FAILED = prefix('LOGIN_FAILED');

export const LOGOUT = prefix('LOGOUT');

export const REFRESH = prefix('REFRESH');
export const REFRESH_SUCCESS = prefix('REFRESH_SUCCESS');
export const REFRESH_FAILED = prefix('REFRESH_FAILED');

export const RESET = prefix('RESET');
export const RESET_SUCCESS = prefix('RESET_SUCCESS');
export const RESET_FAILED = prefix('RESET_FAILED');

export const RECOVER_CONFIRM = prefix('RECOVER_CONFIRM');
export const RECOVER_CONFIRM_SUCCESS = prefix('RECOVER_CONFIRM_SUCCESS');
export const RECOVER_CONFIRM_FAILED = prefix('RECOVER_CONFIRM_FAILED');
