import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import { siteEpisodesLoadingProgress, siteEpisodeDaysLoadingProgress } from 'data/reducers/recommendations';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.recommendations;

export const getSiteEpisodes = state => getState(state).get('episodesEntities');

export const getSiteEpisodesList = createImmutableEqualSelector(getSiteEpisodes, getState, (episodes, state) =>
  denormalize(state.get('siteEpisodes'), [schemas.episode], new Map({ episodes })),
);

export const getSiteEpisodesLoaded = state => siteEpisodesLoadingProgress.getLoaded(getState(state));
export const getSiteEpisodesLoading = state => siteEpisodesLoadingProgress.getLoading(getState(state));
export const getSiteEpisodesLoadFailed = state => siteEpisodesLoadingProgress.getLoadFailed(getState(state));

export const getSiteEpisodeDaysEntities = state => getState(state).get('episodeDaysEntities');

export const getSiteEpisodeDays = createImmutableEqualSelector(
  getSiteEpisodeDaysEntities,
  getState,
  (episodeDays, state) => denormalize(state.get('episodeDays'), [schemas.episodeDay], new Map({ episodeDays })),
);

export const getSiteEpisodeDaysLoaded = state => siteEpisodeDaysLoadingProgress.getLoaded(getState(state));
export const getSiteEpisodeDaysLoading = state => siteEpisodeDaysLoadingProgress.getLoading(getState(state));
export const getSiteEpisodeDaysLoadFailed = state => siteEpisodeDaysLoadingProgress.getLoadFailed(getState(state));
