import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

const SubTitleShared = ({ className, children }) => <div className={cx(s.subTitle, className)}>{children}</div>;

SubTitleShared.defaultProps = {
  className: null,
  children: null,
};

SubTitleShared.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SubTitleShared;
