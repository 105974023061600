const nonEnumerableProperty = value => {
  // The field `enumerable` is `false` by default.
  return {
    value,
    writable: true,
    configurable: true,
  };
};

export class HTTPError extends Error {
  constructor(message, status = 400) {
    super(message);
    this.status = status;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }
    // Generic way to set the error stack trace.
    else Object.defineProperty(this, 'stack', nonEnumerableProperty(Error(message).stack));
  }

  status(status) {
    this.status = status;
    return this;
  }
}

export class ConnectionError extends HTTPError {
  constructor(message) {
    super(message);
    this.status = 504;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConnectionError);
    }
    // Generic way to set the error stack trace.
    else Object.defineProperty(this, 'stack', nonEnumerableProperty(Error(message).stack));
  }

  status(status) {
    this.status = status;
    return this;
  }
}
