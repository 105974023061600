import * as constants from 'data/constants/suppliers';

export const fetchSingle = id => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.LOAD_START });
  try {
    const res = await api.get(`/suppliers/${id}/`);

    dispatch({
      type: constants.LOAD_SUCCESS,
      payload: normalize(res.data, schema.supplier),
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.LOAD_FAILED });
  }
};
export const fetchList = (page = 1, perPage = 50, orderingField, order, search) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get('suppliers/', {
      params: { page, perPage, order, ordering_field: orderingField, search },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.supplier]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};

export const fetchSitesList = (id, page = 1, perPage = 50, orderingField, order, search) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.SITES_LIST_LOAD_START });
  try {
    const res = await api.get(`suppliers/${id}/sites/`, {
      params: { page, perPage, order, ordering_field: orderingField, search },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.site]),
    };

    dispatch({
      type: constants.SITES_LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.SITES_LIST_LOAD_FAILED });

    return e;
  }
};

export const clearList = () => ({ type: constants.CLEAR_LIST });

export const clearSites = () => ({ type: constants.CLEAR_SITES });
