import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  availableDevicesListLoadingProgress,
  devicesListLoadingProgress,
  gatewaysListLoadingProgress,
  itemsPagination,
  listLoadingProgress,
  reasonLoadingProgress,
  sitesListLoadingProgress,
} from 'data/reducers/replacements';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.replacements;

export const getEntities = state => getState(state).get('entities');

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));
export const getItemsTotal = state => itemsPagination.getTotalItems(getState(state));

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (replacements, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.replacement], new Map({ replacements })),
);

export const getReasons = state => getState(state).get('reasons');

export const getReasonsListLoaded = state => reasonLoadingProgress.getLoaded(getState(state));
export const getReasonsListLoading = state => reasonLoadingProgress.getLoading(getState(state));
export const getReasonsListLoadFailed = state => reasonLoadingProgress.getLoadFailed(getState(state));

export const getSites = state => getState(state).get('sites');

export const getSitesListLoaded = state => sitesListLoadingProgress.getLoaded(getState(state));
export const getSitesListLoading = state => sitesListLoadingProgress.getLoading(getState(state));
export const getSitesListLoadFailed = state => sitesListLoadingProgress.getLoadFailed(getState(state));

export const getDevices = state => getState(state).get('devices');

export const getDevicesListLoaded = state => devicesListLoadingProgress.getLoaded(getState(state));
export const getDevicesListLoading = state => devicesListLoadingProgress.getLoading(getState(state));
export const getDevicesListLoadFailed = state => devicesListLoadingProgress.getLoadFailed(getState(state));

export const getAvailableDevices = state => getState(state).get('availableDevices');

export const getAvailableDevicesListLoaded = state => availableDevicesListLoadingProgress.getLoaded(getState(state));
export const getAvailableDevicesListLoading = state => availableDevicesListLoadingProgress.getLoading(getState(state));
export const getAvailableDevicesListLoadFailed = state =>
  availableDevicesListLoadingProgress.getLoadFailed(getState(state));

export const getGateways = state => getState(state).get('gateways');

export const getGatewaysListLoaded = state => gatewaysListLoadingProgress.getLoaded(getState(state));
export const getGatewaysListLoading = state => gatewaysListLoadingProgress.getLoading(getState(state));
export const getGatewaysListLoadFailed = state => gatewaysListLoadingProgress.getLoadFailed(getState(state));
