const colors = {
  white: '#FFF',
  primary: '#263238',
  dark: '#014684',
  accent: '#CDDC39',
  secondary: '#7C7C7C',
  border: '#bfbfbf',
  borderLight: '#ededed',
  background: '#F6F6F6',
  success: '#63af10',
  success500: '#63AF11',
  warning: '#fe9000',
  error: '#d0021b',
  error300: '#FFD8D8',
  error600: '#FF9C9C',
  alert500: '#FEB300',

  additional: '#f4f7fd',
  additionalBackground: '#f8f9f8',

  graphAreaRed: '#f6ccd1',
  graphAreaYellow: '#fcf0cd',
  graphAreaGreen: '#ecf7e0',

  graphLineRed: '#d33c4b',
  graphLineYellow: '#fac343',
  graphLineGreen: '#84be48',

  statusBlue: '#032954',
  statusPurple: '#aa53dd',
  statusGrey: '#979797',

  base800: '#54646C',
  base700: '#757575',
  base600: '#9E9E9E',
  tertiary700: '#0065A9',
  quaternary500: '#70C586',
  primary700: '#A8B900',
  secondary500: '#B845E5',
  secondary200: '#EED2F9',
};

colors.shadow = {
  color: colors.primary,
  opacity: 0.1,
};

colors.greenGradient = {
  start: '#ACDE66',
  end: colors.accent,
};

export default colors;
