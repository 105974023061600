/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './styles.scss';

const createRoundedButton = initClassName => {
  const ButtonText = ({ children, active }) => <span className={cx(s.text, active && s.textActive)}>{children}</span>;

  ButtonText.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.string.isRequired,
  };

  ButtonText.defaultProps = {
    active: false,
  };

  const Button = ({ className, children, active, ...props }) => (
    <button className={cx(s.roundedButton, initClassName, active && s.roundedButtonActive, className)} {...props}>
      {typeof children === 'string' ? <ButtonText active={active}>{children}</ButtonText> : children}
    </button>
  );

  Button.Text = ButtonText;

  Button.defaultProps = {
    className: null,
    active: false,
  };

  Button.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  return Button;
};

export const RoundedButton = createRoundedButton();
export const PrimaryRoundedButton = createRoundedButton(s.roundedButtonPrimary);
