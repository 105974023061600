import * as constants from 'data/constants/sites';
import { saveBlob } from 'data/utils/actions';
import { externalBaseUrl } from 'config';

export const fetchList = (page, perPage, search, systemId, online, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get('sites/', {
      params: { page, perPage, search, system_id: systemId, online, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.site]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};

export const fetchStats = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.STATS_LOAD_START });
  try {
    const res = await api.get('sites/stats/');

    dispatch({
      type: constants.STATS_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.STATS_LOAD_FAILED });

    return e;
  }
};

export const fetchSingle = id => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.LOAD_START });
  try {
    const res = await api.get(`/sites/${id}/`);

    dispatch({
      type: constants.LOAD_SUCCESS,
      payload: normalize(res.data, schema.site),
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.LOAD_FAILED });
  }
};

export const fetchSingleStatus = id => async (dispatch, _, { api }) => {
  dispatch({ type: constants.STATUS_LOAD_START });
  try {
    const res = await api.get(`/sites/${id}/status/`);

    dispatch({
      type: constants.STATUS_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.STATUS_LOAD_FAILED });
  }
};

export const downloadList = (fileType, search, systemId, online) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.LIST_DOWNLOAD_START });
  try {
    const res = await api.get('sites/download/', {
      params: { file_type: fileType, search, system_id: systemId, online },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.LIST_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'sites-list', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_DOWNLOAD_FAILED });

    return e;
  }
};

export const fetchDevices = (id, page = 1, perPage = 100, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.DEVICE_LIST_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/devices/`, {
      params: { page, perPage, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.monitor]),
    };

    dispatch({ type: constants.DEVICE_LIST_LOAD_SUCCESS, payload });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.DEVICE_LIST_LOAD_FAILED });
  }
};

export const fetchGateways = (id, page = 1, perPage = 100, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.GATEWAY_LIST_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/gateways/`, {
      params: { page, perPage, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.gateway]),
    };

    dispatch({ type: constants.GATEWAY_LIST_LOAD_SUCCESS, payload });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.GATEWAY_LIST_LOAD_FAILED });
  }
};

export const fetchAlerts = (id, page, perPage, alarmPriority, alarmType, airspaceId, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.ALERTS_LIST_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/notifications/alerts/`, {
      params: {
        page,
        perPage,
        alarm_priority: alarmPriority,
        alarm_type: alarmType,
        airspace_id: airspaceId,
        ordering_field: orderingField,
        order,
      },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.alert]),
    };

    dispatch({ type: constants.ALERTS_LIST_LOAD_SUCCESS, payload });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.ALERTS_LIST_LOAD_FAILED });
  }
};

export const downloadAlerts = (id, fileType, alarmPriority, alarmType, airspaceId) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.ALERTS_LIST_DOWNLOAD_START });
  try {
    const res = await api.get(`sites/${id}/notifications/alerts/download/`, {
      params: {
        alarm_priority: alarmPriority,
        alarm_type: alarmType,
        airspace_id: airspaceId,
      },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.ALERTS_LIST_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'alerts-list', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.ALERTS_LIST_DOWNLOAD_FAILED });

    return e;
  }
};

export const fetchNotifications = (id, page, perPage, airspaceId, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.NOTIFICATIONS_LIST_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/notifications/`, {
      params: {
        page,
        perPage,
        airspace_id: airspaceId,
        ordering_field: orderingField,
        order,
      },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.notification]),
    };

    dispatch({ type: constants.NOTIFICATIONS_LIST_LOAD_SUCCESS, payload });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.NOTIFICATIONS_LIST_LOAD_FAILED });
  }
};

export const downloadNotifications = (id, fileType, airspaceId) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.NOTIFICATIONS_LIST_DOWNLOAD_START });
  try {
    const res = await api.get(`sites/${id}/notifications/download/`, {
      params: {
        airspace_id: airspaceId,
      },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.NOTIFICATIONS_LIST_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'notifications-list', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.NOTIFICATIONS_LIST_DOWNLOAD_FAILED });

    return e;
  }
};

export const fetchPushNotifications = (id, page, perPage, airspaceId, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/push-notifications/`, {
      params: {
        page,
        perPage,
        airspace_id: airspaceId,
        ordering_field: orderingField,
        order,
      },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.pushNotification]),
    };

    dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS, payload });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_LOAD_FAILED });
  }
};

export const downloadPushNotifications = (id, fileType, airspaceId) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_DOWNLOAD_START });
  try {
    const res = await api.get(`sites/${id}/push-notifications/download/`, {
      params: {
        airspace_id: airspaceId,
      },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.PUSH_NOTIFICATIONS_LIST_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'push-notifications-list', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_DOWNLOAD_FAILED });

    return e;
  }
};

export const fetchAirspaces = (id, page = 1, perPage = 100, search) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.AIRSPACES_LIST_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/airspaces/`, {
      params: { page, perPage, search },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.airspace]),
    };

    dispatch({ type: constants.AIRSPACES_LIST_LOAD_SUCCESS, payload });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.AIRSPACES_LIST_LOAD_FAILED });
  }
};

export const updateSiteInfo = (values, actions) => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.INFO_UPDATE_START });

  actions.setSubmitting(true);
  try {
    const { id } = values;
    const res = await api.patch(`sites/${id}/`, values);

    actions.setSubmitting(false);
    actions.setStatus(true);
    dispatch({
      type: constants.INFO_UPDATE_SUCCESS,
      payload: normalize(res.data, schema.site),
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e.response && e.response.data);
    dispatch({ type: constants.INFO_UPDATE_FAILED });
    if (e.response && e.response.data) {
      const errors = {};
      // eslint-disable-next-line
      Object.keys(e.response.data).forEach(key => (errors[key] = e.response.data[key][0]));
      actions.setErrors(errors);
    }
    actions.setSubmitting(false);

    return false;
  }
};

export const fetchHeatmap = (id, sensor, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.SITE_HEATMAP_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`sites/${id}/airspaces/heatmaps/`, {
      params: { sensor, start_date: from, end_date: to },
    });

    dispatch({
      type: constants.SITE_HEATMAP_LOAD_SUCCESS,
      payload: { data: res.data },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.SITE_HEATMAP_LOAD_FAILED });
  }
};

export const clearHeatmap = () => ({ type: constants.CLEAR_HEATMAP });

export const fetchMapList = search => async (dispatch, _, { api }) => {
  dispatch({ type: constants.MAP_LIST_LOAD_START });
  try {
    const res = await api.get('sites/map/', {
      params: { search },
    });

    dispatch({
      type: constants.MAP_LIST_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.MAP_LIST_LOAD_FAILED });

    return e;
  }
};

export const downloadHeatmap = (id, fileType, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.SITE_HEATMAP_DOWNLOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`sites/${id}/airspaces/data/download`, {
      params: { start_date: from, end_date: to },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.SITE_HEATMAP_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'site-heatmap', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.SITE_HEATMAP_DOWNLOAD_FAILED });

    return e;
  }
};

export const fetchDevicesAvailability = (id, sensor) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.DEVICES_AVAILABILITY_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/devices/availability`, {
      params: {
        sensor,
      },
    });

    dispatch({ type: constants.DEVICES_AVAILABILITY_LOAD_SUCCESS, payload: res.data });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.DEVICES_AVAILABILITY_LOAD_FAILED });
  }
};

export const fetchSystems = id => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.SYSTEMS_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/systems/`);

    dispatch({
      type: constants.SYSTEMS_LOAD_SUCCESS,
      payload: normalize(res.data, [schema.system]),
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.SYSTEMS_LOAD_FAILED });

    return e;
  }
};

export const createSystem = (id, name, actions) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.SYSTEMS_CREATION_START });
  try {
    const res = await api.post(`sites/${id}/systems/`, { name });
    actions.setSubmitting(false);
    actions.setStatus(true);

    dispatch({
      type: constants.SYSTEMS_CREATION_SUCCESS,
    });
    return res.data?.id;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.SYSTEMS_CREATION_FAILED });
    actions.setErrors({ newSystem: e.response.data.name[0] });
    actions.setSubmitting(false);
    return false;
  }
};

export const assignLicenses = (values, actions) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.ASSIGN_LICENSES_START });
  actions.setSubmitting(true);
  try {
    const { id } = values;
    const res = await api.patch(`/licenses/sites/${id}`, values);

    actions.setSubmitting(false);
    actions.setStatus(true);
    dispatch({ type: constants.ASSIGN_LICENSES_SUCCESS, payload: res });
    return true;
  } catch (e) {
    console.error(e.response && e.response.data);
    dispatch({ type: constants.ASSIGN_LICENSES_FAILED });
    if (e.response && e.response.data) {
      actions.setErrors({ message: e.response.data.message });
    }
    actions.setSubmitting(false);
    return false;
  }
};

export const fetchDiseaseHistory = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.DISEASE_HISTORY_LOAD_START });
  try {
    const res = await api.get(`${externalBaseUrl}/sites/disease-histories/`);

    dispatch({
      type: constants.DISEASE_HISTORY_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: constants.DISEASE_HISTORY_LOAD_FAILED });
  }
};

export const filterStatus = status => ({ type: constants.FILTER_STATUS, payload: status });

export const filterType = type => ({ type: constants.FILTER_TYPE, payload: type });

export const filterSearch = search => ({ type: constants.FILTER_SEARCH, payload: search });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });

export const clearStats = () => ({ type: constants.CLEAR_STATS });

export const clearDevicesAvailability = () => ({ type: constants.CLEAR_DEVICES_AVAILABILITY });
