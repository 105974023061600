import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PrimaryButton as UiPrimaryButton } from 'ui';

import s from './styles.scss';

export const PrimaryButton = ({ className, children, ...props }) => (
  <UiPrimaryButton className={cx(s.buttonPrimary, className)} {...props}>
    {children}
  </UiPrimaryButton>
);

PrimaryButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

PrimaryButton.defaultProps = {
  className: null,
  children: null,
};
