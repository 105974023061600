import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import { loadingProgress, listLoadingProgress, itemsPagination } from 'data/reducers/systems';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.systems;

export const getEntities = state => getState(state).get('entities');

export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (systems, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.system], new Map({ systems })),
);

export const getItemsTotal = state => itemsPagination.getTotalItems(getState(state));

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = state => loadingProgress.getLoaded(getState(state));
export const getItemLoading = state => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = state => loadingProgress.getLoadFailed(getState(state));
