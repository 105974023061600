export const sizes = {
  base: 16,
  tabs: 12,
  logo: 32,
  button: 32,
  touchable: 16,
  floating: 20,
  radius: 2,
  borderWidth: 0.5,
};
