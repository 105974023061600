const prefix = type => `opu/exports/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LIST_DOWNLOAD_START = prefix('LIST_DOWNLOAD_START');
export const LIST_DOWNLOAD_SUCCESS = prefix('LIST_DOWNLOAD_SUCCESS');
export const LIST_DOWNLOAD_FAILED = prefix('LIST_DOWNLOAD_FAILED');

export const EXPORT_START_START = prefix('EXPORT_START_START');
export const EXPORT_START_SUCCESS = prefix('EXPORT_START_SUCCESS');
export const EXPORT_START_FAILED = prefix('EXPORT_START_FAILED');

export const CLEAR = prefix('CLEAR');
