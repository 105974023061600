import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { actions } from 'data';

import { signInPath } from 'constants/urls';

import { Formik } from 'formik';
import { WithTranslateFormErrors } from 'utils/translation-validation';
import RecoverPasswordForm from 'components/shared/recover-password';

import { RecoverPasswordSchema } from 'utils/validation';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  recoverPassword: actions.session.recoverPassword,
};
class ResetPassword extends React.Component {
  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    recoverPassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    token: null,
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const token = search.split('&')[0].substring(7);

    this.setState({ token });
  }

  handleRecover = (values, formik) => {
    const credentials = {
      password: values.password,
      token: this.state.token,
    };

    this.props.recoverPassword(credentials, formik);
  };

  render() {
    return (
      <Formik
        initialValues={{
          password: '',
          confirm_password: '',
        }}
        validationSchema={RecoverPasswordSchema(this.props.t)}
        onSubmit={this.handleRecover}
        render={({ status, ...formikProps }) => {
          if (status) {
            return (
              <Redirect to={this.props.location.state ? this.props.location.state.location.pathname : signInPath} />
            );
          }
          return (
            <WithTranslateFormErrors {...formikProps}>
              <RecoverPasswordForm {...formikProps} />
            </WithTranslateFormErrors>
          );
        }}
      />
    );
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ResetPassword),
);
