import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { refreshSession, logout } from 'data/actions/session';
import { getRefreshFailed, getRefreshLoading } from 'data/selectors/session';
import {
  getUnauthorized,
  getServerError,
  getNotFound,
  getConnectionError,
  getSuccess,
  getForbidden,
} from 'data/selectors/api';
import { RouterPropTypes } from 'utils/prop-types';
import { appNotFoundPath, appForbiddenPath, appServerErrorPath } from 'constants/urls';

const mapStateToProps = state => ({
  refreshFailed: getRefreshFailed(state),
  refreshLoading: getRefreshLoading(state),
  unauthorized: getUnauthorized(state),
  serverError: getServerError(state),
  notFound: getNotFound(state),
  success: getSuccess(state),
  forbidden: getForbidden(state),
});

const mapDispatchToProps = {
  refreshSession,
  logout,
};

class ApiInterceptor extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    refreshSession: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    refreshFailed: PropTypes.bool,
    refreshLoading: PropTypes.bool,
    unauthorized: PropTypes.bool.isRequired,
    history: RouterPropTypes.history.isRequired,
    serverError: PropTypes.bool.isRequired,
    notFound: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    placeholder: PropTypes.node,
    forbidden: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    refreshFailed: false,
    refreshLoading: false,
    placeholder: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.refreshFailed) {
      this.props.logout();
    } else if (this.props.unauthorized && !prevProps.unauthorized) {
      this.props.refreshSession();
    } else if (this.props.notFound) {
      this.props.history.push(appNotFoundPath);
    } else if (this.props.forbidden) {
      this.props.history.push(appForbiddenPath);
    } else if (this.props.serverError) {
      this.props.history.push(appServerErrorPath);
    } else if (!prevProps.success && this.props.success) {
      console.log('Everything is awesome');
    }
  }

  render() {
    return this.props.refreshLoading ? this.props.placeholder : this.props.children;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApiInterceptor);
