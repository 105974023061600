const prefix = type => `opu/replacements/${type}`;

export const REASONS_LOAD_START = prefix('REASONS_LOAD_START');
export const REASONS_LOAD_SUCCESS = prefix('REASONS_LOAD_SUCCESS');
export const REASONS_LOAD_FAILED = prefix('REASONS_LOAD_FAILED');

export const GATEWAYS_LOAD_START = prefix('GATEWAYS_LOAD_START');
export const GATEWAYS_LOAD_SUCCESS = prefix('GATEWAYS_LOAD_SUCCESS');
export const GATEWAYS_LOAD_FAILED = prefix('GATEWAYS_LOAD_FAILED');

export const DEVICES_LOAD_START = prefix('DEVICES_LOAD_START');
export const DEVICES_LOAD_SUCCESS = prefix('DEVICES_LOAD_SUCCESS');
export const DEVICES_LOAD_FAILED = prefix('DEVICES_LOAD_FAILED');

export const AVAILABLE_DEVICES_LOAD_START = prefix('AVAILABLE_DEVICES_LOAD_START');
export const AVAILABLE_DEVICES_LOAD_SUCCESS = prefix('AVAILABLE_DEVICES_LOAD_SUCCESS');
export const AVAILABLE_DEVICES_LOAD_FAILED = prefix('AVAILABLE_DEVICES_LOAD_FAILED');

export const SITES_LOAD_START = prefix('SITES_LOAD_START');
export const SITES_LOAD_SUCCESS = prefix('SITES_LOAD_SUCCESS');
export const SITES_LOAD_FAILED = prefix('SITES_LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const REQUEST_CREATION_START = prefix('REQUEST_CREATION_START');
export const REQUEST_CREATION_SUCCESS = prefix('REQUEST_CREATION_SUCCESS');
export const REQUEST_CREATION_FAILED = prefix('REQUEST_CREATION_FAILED');

export const REPLACEMENTS_CANCEL_START = prefix('REPLACEMENTS_CANCEL_START');
export const REPLACEMENTS_CANCEL_SUCCESS = prefix('REPLACEMENTS_CANCEL_SUCCESS');
export const REPLACEMENTS_CANCEL_FAILED = prefix('REPLACEMENTS_CANCEL_FAILED');

export const REPLACEMENTS_UPDATE_START = prefix('REPLACEMENTS_UPDATE_START');
export const REPLACEMENTS_UPDATE_SUCCESS = prefix('REPLACEMENTS_UPDATE_SUCCESS');
export const REPLACEMENTS_UPDATE_FAILED = prefix('REPLACEMENTS_UPDATE_FAILED');
