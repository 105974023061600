import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

const FooterShared = ({ className, children, ...props }) => (
  <footer {...props} className={cx(s.footer, className)}>
    {children}
  </footer>
);

FooterShared.defaultProps = {
  className: null,
  children: null,
};

FooterShared.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FooterShared;
