import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { actions } from 'data';

import { resetPasswordSuccessPath } from 'constants/urls';

import { Formik } from 'formik';
import { WithTranslateFormErrors } from 'utils/translation-validation';
import ResetPasswordForm from 'components/shared/reset-password';

import { ResetPasswordSchema } from 'utils/validation';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  resetPassword: actions.session.resetPassword,
};

class ResetPassword extends React.Component {
  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    resetPassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  render() {
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={ResetPasswordSchema(this.props.t)}
        onSubmit={this.props.resetPassword}
        render={({ status, ...formikProps }) => {
          if (status) {
            return (
              <Redirect
                to={this.props.location.state ? this.props.location.state.location.pathname : resetPasswordSuccessPath}
              />
            );
          }
          return (
            <WithTranslateFormErrors {...formikProps}>
              <ResetPasswordForm {...formikProps} />
            </WithTranslateFormErrors>
          );
        }}
      />
    );
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ResetPassword),
);
