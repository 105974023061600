import { tokenLoading, refreshLoading } from 'data/reducers/session';

const getState = store => store.session;

export const getAccess = store => getState(store).get('access');

export const getSessionActive = store =>
  tokenLoading.getLoaded(getState(store)) || refreshLoading.getLoaded(getState(store));

export const getRefresh = store => getState(store).get('refresh');

export const getRefreshSuccess = store => refreshLoading.getLoaded(getState(store));

export const getRefreshLoading = store => refreshLoading.getLoading(getState(store));

export const getRefreshFailed = store => refreshLoading.getLoadFailed(getState(store));
