import * as constants from 'data/constants/users';

export const fetchList = (page, perPage, orderingField, order) => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get('users/', {
      params: { page, perPage, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.user]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};

export const fetchSuppliers = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.SUPPLIERS_LIST_LOAD_START });
  try {
    const res = await api.get('users/suppliers/');

    dispatch({
      type: constants.SUPPLIERS_LIST_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.SUPPLIERS_LIST_LOAD_FAILED });

    return e;
  }
};

export const addUser = (values, actions) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.ADD_START });
  actions.setSubmitting(true);
  try {
    const res = await api.post(`/users/`, values);

    actions.setSubmitting(false);
    actions.setStatus(true);
    dispatch({ type: constants.ADD_SUCCESS, payload: res.data });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e.response?.data);
    // dispatch({ type: constants.ADD_FAILED });

    if (e.response?.data) {
      actions.setErrors({ supplier: e.response.data[0] });
    }
    actions.setSubmitting(false);

    return false;
  }
};

export const fetchUserRoles = (id, orderingField, order) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.ROLES_LIST_LOAD_START });
  try {
    const res = await api.get(`users/${id}/roles/`, {
      params: { ordering_field: orderingField, order },
    });

    dispatch({
      type: constants.ROLES_LIST_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.ROLES_LIST_LOAD_FAILED });

    return e;
  }
};

export const editRole = (userId, userRoleId, values) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.ROLE_EDIT_START });
  try {
    const res = await api.patch(`/users/${userId}/roles/${userRoleId}/`, values);
    if (res.status !== 200) throw new Error(res.statusText);
    dispatch({ type: constants.ROLE_EDIT_SUCCESS });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e.response.data);
    dispatch({ type: constants.ROLE_EDIT_FAILED });

    return false;
  }
};

export const fetchRoles = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.ROLES_LOAD_START });
  try {
    const res = await api.get('users/roles/');

    dispatch({
      type: constants.ROLES_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.ROLES_LOAD_FAILED });

    return e;
  }
};

export const registerUser = (values, actions) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.REGISTER_START });
  actions.setSubmitting(true);
  try {
    const res = await api.post(`/users/register/`, values);

    actions.setSubmitting(false);
    actions.setStatus(true);
    dispatch({ type: constants.REGISTER_SUCCESS, payload: res.data });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e.response?.data);
    dispatch({ type: constants.REGISTER_FAILED });

    if (e.response?.data) {
      const errors = {};
      // eslint-disable-next-line
      Object.keys(e.response.data).forEach(key => (errors[key] = e.response.data[key][0]));
      actions.setErrors(errors);
    }
    actions.setSubmitting(false);

    return false;
  }
};
