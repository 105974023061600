import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  itemsPagination,
  sitesListLoadingProgress,
  sitesItemsPagination,
} from 'data/reducers/suppliers';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.suppliers;

export const getEntities = state => getState(state).get('entities');

export const getItem = (state, id) => getEntities(state).get(String(id));

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (suppliers, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.supplier], new Map({ suppliers })),
);

export const getItemsTotal = state => itemsPagination.getTotalItems(getState(state));

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = state => loadingProgress.getLoaded(getState(state));
export const getItemLoading = state => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = state => loadingProgress.getLoadFailed(getState(state));

// Sites

export const getSitesEntities = state => getState(state).get('sites');

export const getSitesItemsList = createImmutableEqualSelector(getSitesEntities, getState, (sites, state) =>
  denormalize(sitesItemsPagination.getPageItems(state), [schemas.site], new Map({ sites })),
);

export const getSitesItemsTotal = state => sitesItemsPagination.getTotalItems(getState(state));

export const getSitesItemsListLoaded = state => sitesListLoadingProgress.getLoaded(getState(state));
export const getSitesItemsListLoading = state => sitesListLoadingProgress.getLoading(getState(state));
export const getSitesItemsListLoadFailed = state => sitesListLoadingProgress.getLoadFailed(getState(state));
