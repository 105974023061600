export const basePath = '/';

const applyBasePathTo = path => `${basePath}${path}`;

export const paginatedPath = path => page => `${path}/page/${page}`;

export const doublePaginatedPath = path => page1 => page2 => `${path}/page/${page1}/${page2}`;

export const reverseDoublePaginatedPath = path => page2 => page1 => `${path}/page/${page1}/${page2}`;

// Shared

export const baseErrorPath = applyBasePathTo('error');

export const signInPath = applyBasePathTo('sign-in');

export const recoverPasswordPath = applyBasePathTo('recover-password');

export const resetPasswordPath = applyBasePathTo('reset-password');

export const resetPasswordSuccessPath = applyBasePathTo('reset-password-success');

export const signOutPath = applyBasePathTo('sign-out');

export const registrationPath = applyBasePathTo('registration');

export const registrationSuccessPath = applyBasePathTo('registration-success');

export const notFoundPath = applyBasePathTo('not-found');

// App

export const baseAppPath = applyBasePathTo('app');

export const profilePath = `${baseAppPath}/profile`;

export const profileHelpCenterPath = `${profilePath}/help-center`;

export const appSitesPath = `${baseAppPath}/sites`;

export const appGatewaysPath = `${baseAppPath}/gateways`;

export const appMonitorsPath = `${baseAppPath}/monitors`;

export const appUserManagementPath = `${baseAppPath}/user-management`;

export const appToolboxPath = `${baseAppPath}/toolbox`;

export const errorPath = `${baseAppPath}/error`;

export const appNotFoundPath = `${baseAppPath}/not-found`;

export const appForbiddenPath = `${baseAppPath}/forbidden`;

export const appServerErrorPath = `${baseAppPath}/server-error`;

// App sites

export const appSitePath = site => `${appSitesPath}/${site}`;

export const appSitesMapPath = `${appSitesPath}/map`;

export const appSitesListPath = `${appSitesPath}/list`;

export const appSiteInfoPath = site => `${appSitePath(site)}/info`;

export const siteRecommendationsPath = site => `${appSitePath(site)}/recommendations`;

export const appSiteDataPath = site => `${appSitePath(site)}/data`;

export const appSiteHeatmapsPath = site => `${appSitePath(site)}/heatmaps`;

export const appSiteLicensePath = site => `${appSitePath(site)}/license`;

export const appSiteExportPath = site => `${appSitePath(site)}/export`;

export const appSiteDataAvailabilityPath = site => `${appSiteDataPath(site)}/data-availability`;

export const appSiteSensorDataPath = site => `${appSiteDataPath(site)}/sensor-data`;

export const appSiteAlertsNotificationsPath = site => `${appSitePath(site)}/alerts-notifications`;

export const appSiteAlertsListPath = site => `${appSiteAlertsNotificationsPath(site)}/alerts`;

export const appSiteNotificationsListPath = site => `${appSiteAlertsNotificationsPath(site)}/notifications`;

export const appSitePushNotificationsListPath = site => `${appSiteAlertsNotificationsPath(site)}/push-notifications`;

// App gateways

export const appGatewayPath = gateway => `${appGatewaysPath}/${gateway}`;

export const appGatewaysListPath = `${appGatewaysPath}/list`;

// App monitors

export const appMonitorPath = monitor => `${appMonitorsPath}/${monitor}`;

export const appMonitorsListPath = `${appMonitorsPath}/list`;

export const appMonitorDashboardPath = monitor => `${appMonitorPath(monitor)}/dashboard`;

export const appMonitorInfoPath = monitor => `${appMonitorPath(monitor)}/info`;

export const appMonitorGraphsPath = monitor => `${appMonitorPath(monitor)}/graphs`;

export const appMonitorHeatmapsPath = monitor => `${appMonitorPath(monitor)}/heatmaps`;

export const appMonitorDataAvailabilityPath = monitor => `${appMonitorGraphsPath(monitor)}/data-availability`;

export const appMonitorRdiTemHumPath = monitor => `${appMonitorGraphsPath(monitor)}/rdi-temperature-humidity`;

export const appMonitorLogPath = monitor => `${appMonitorPath(monitor)}/log`;

// App user management

export const appUsersListPath = `${appUserManagementPath}/list`;

export const appUserPath = user => `${appUserManagementPath}/user/${user}`;

// App tookbox

export const appToolboxDeviceReplacementsPath = `${appToolboxPath}/device-replacements`;

export const appToolboxDeviceReplacementsRequestPath = `${appToolboxDeviceReplacementsPath}/new-request`;

export const appToolboxSubscriptionsPath = `${appToolboxPath}/subscriptions`;

export const appToolboxSubscriptionsImportPath = `${appToolboxSubscriptionsPath}/import`;
