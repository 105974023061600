const prefix = type => `opu/sites/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const MAP_LIST_LOAD_START = prefix('MAP_LIST_LOAD_START');
export const MAP_LIST_LOAD_SUCCESS = prefix('MAP_LIST_LOAD_SUCCESS');
export const MAP_LIST_LOAD_FAILED = prefix('MAP_LIST_LOAD_FAILED');

export const LIST_DOWNLOAD_START = prefix('LIST_DOWNLOAD_START');
export const LIST_DOWNLOAD_SUCCESS = prefix('LIST_DOWNLOAD_SUCCESS');
export const LIST_DOWNLOAD_FAILED = prefix('LIST_DOWNLOAD_FAILED');

export const DEVICE_LIST_LOAD_START = prefix('DEVICE_LIST_LOAD_START');
export const DEVICE_LIST_LOAD_SUCCESS = prefix('DEVICE_LIST_LOAD_SUCCESS');
export const DEVICE_LIST_LOAD_FAILED = prefix('DEVICE_LIST_LOAD_FAILED');

export const GATEWAY_LIST_LOAD_START = prefix('GATEWAY_LIST_LOAD_START');
export const GATEWAY_LIST_LOAD_SUCCESS = prefix('GATEWAY_LIST_LOAD_SUCCESS');
export const GATEWAY_LIST_LOAD_FAILED = prefix('GATEWAY_LIST_LOAD_FAILED');

export const STATUS_LOAD_START = prefix('STATUS_LOAD_START');
export const STATUS_LOAD_SUCCESS = prefix('STATUS_LOAD_SUCCESS');
export const STATUS_LOAD_FAILED = prefix('STATUS_LOAD_FAILED');

export const STATS_LOAD_START = prefix('STATS_LOAD_START');
export const STATS_LOAD_SUCCESS = prefix('STATS_LOAD_SUCCESS');
export const STATS_LOAD_FAILED = prefix('STATS_LOAD_FAILED');

export const ALERTS_LIST_LOAD_START = prefix('ALERTS_LIST_LOAD_START');
export const ALERTS_LIST_LOAD_SUCCESS = prefix('ALERTS_LIST_LOAD_SUCCESS');
export const ALERTS_LIST_LOAD_FAILED = prefix('ALERTS_LIST_LOAD_FAILED');

export const NOTIFICATIONS_LIST_LOAD_START = prefix('NOTIFICATIONS_LIST_LOAD_START');
export const NOTIFICATIONS_LIST_LOAD_SUCCESS = prefix('NOTIFICATIONS_LIST_LOAD_SUCCESS');
export const NOTIFICATIONS_LIST_LOAD_FAILED = prefix('NOTIFICATIONS_LIST_LOAD_FAILED');

export const PUSH_NOTIFICATIONS_LIST_LOAD_START = prefix('PUSH_NOTIFICATIONS_LIST_LOAD_START');
export const PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS = prefix('PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS');
export const PUSH_NOTIFICATIONS_LIST_LOAD_FAILED = prefix('PUSH_NOTIFICATIONS_LIST_LOAD_FAILED');

export const ALERTS_LIST_DOWNLOAD_START = prefix('ALERTS_LIST_DOWNLOAD_START');
export const ALERTS_LIST_DOWNLOAD_SUCCESS = prefix('ALERTS_LIST_DOWNLOAD_SUCCESS');
export const ALERTS_LIST_DOWNLOAD_FAILED = prefix('ALERTS_LIST_DOWNLOAD_FAILED');

export const NOTIFICATIONS_LIST_DOWNLOAD_START = prefix('NOTIFICATIONS_LIST_DOWNLOAD_START');
export const NOTIFICATIONS_LIST_DOWNLOAD_SUCCESS = prefix('NOTIFICATIONS_LIST_DOWNLOAD_SUCCESS');
export const NOTIFICATIONS_LIST_DOWNLOAD_FAILED = prefix('NOTIFICATIONS_LIST_DOWNLOAD_FAILED');

export const PUSH_NOTIFICATIONS_LIST_DOWNLOAD_START = prefix('PUSH_NOTIFICATIONS_LIST_DOWNLOAD_START');
export const PUSH_NOTIFICATIONS_LIST_DOWNLOAD_SUCCESS = prefix('PUSH_NOTIFICATIONS_LIST_DOWNLOAD_SUCCESS');
export const PUSH_NOTIFICATIONS_LIST_DOWNLOAD_FAILED = prefix('PUSH_NOTIFICATIONS_LIST_DOWNLOAD_FAILED');

export const AIRSPACES_LIST_LOAD_START = prefix('AIRSPACES_LIST_LOAD_START');
export const AIRSPACES_LIST_LOAD_SUCCESS = prefix('AIRSPACES_LIST_LOAD_SUCCESS');
export const AIRSPACES_LIST_LOAD_FAILED = prefix('AIRSPACES_LIST_LOAD_FAILED');

export const INFO_UPDATE_START = prefix('INFO_UPDATE_START');
export const INFO_UPDATE_SUCCESS = prefix('INFO_UPDATE_SUCCESS');
export const INFO_UPDATE_FAILED = prefix('INFO_UPDATE_FAILED');

export const SITE_HEATMAP_LOAD_START = prefix('SITE_HEATMAP_LOAD_START');
export const SITE_HEATMAP_LOAD_SUCCESS = prefix('SITE_HEATMAP_LOAD_SUCCESS');
export const SITE_HEATMAP_LOAD_FAILED = prefix('SITE_HEATMAP_LOAD_FAILED');

export const DEVICE_HEATMAP_LOAD_START = prefix('DEVICE_HEATMAP_LOAD_START');
export const DEVICE_HEATMAP_LOAD_SUCCESS = prefix('DEVICE_HEATMAP_LOAD_SUCCESS');
export const DEVICE_HEATMAP_LOAD_FAILED = prefix('DEVICE_HEATMAP_LOAD_FAILED');

export const SITE_HEATMAP_DOWNLOAD_START = prefix('SITE_HEATMAP_DOWNLOAD_START');
export const SITE_HEATMAP_DOWNLOAD_SUCCESS = prefix('SITE_HEATMAP_DOWNLOAD_SUCCESS');
export const SITE_HEATMAP_DOWNLOAD_FAILED = prefix('SITE_HEATMAP_DOWNLOAD_FAILED');

export const DEVICES_AVAILABILITY_LOAD_START = prefix('DEVICES_AVAILABILITY_LOAD_START');
export const DEVICES_AVAILABILITY_LOAD_SUCCESS = prefix('DEVICES_AVAILABILITY_LOAD_SUCCESS');
export const DEVICES_AVAILABILITY_LOAD_FAILED = prefix('DEVICES_AVAILABILITY_LOAD_FAILED');

export const SYSTEMS_LOAD_START = prefix('SYSTEMS_LOAD_START');
export const SYSTEMS_LOAD_SUCCESS = prefix('SYSTEMS_LOAD_SUCCESS');
export const SYSTEMS_LOAD_FAILED = prefix('SYSTEMS_LOAD_FAILED');

export const SYSTEMS_CREATION_START = prefix('SYSTEMS_CREATION_START');
export const SYSTEMS_CREATION_SUCCESS = prefix('SYSTEMS_CREATION_SUCCESS');
export const SYSTEMS_CREATION_FAILED = prefix('SYSTEMS_CREATION_FAILED');

export const ASSIGN_LICENSES_START = prefix('ASSIGN_LICENSES_START');
export const ASSIGN_LICENSES_SUCCESS = prefix('ASSIGN_LICENSES_SUCCESS');
export const ASSIGN_LICENSES_FAILED = prefix('ASSIGN_LICENSES_FAILED');

export const DISEASE_HISTORY_LOAD_START = prefix('DISEASE_HISTORY_LOAD_START');
export const DISEASE_HISTORY_LOAD_SUCCESS = prefix('DISEASE_HISTORY_LOAD_SUCCESS');
export const DISEASE_HISTORY_LOAD_FAILED = prefix('DISEASE_HISTORY_LOAD_FAILED');

export const FILTER_STATUS = prefix('FILTER_STATUS');

export const FILTER_TYPE = prefix('FILTER_TYPE');

export const FILTER_SEARCH = prefix('FILTER_SEARCH');

export const CLEAR_HEATMAP = prefix('CLEAR_HEATMAP');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');

export const CLEAR_STATS = prefix('CLEAR_STATS');

export const CLEAR_DEVICES_AVAILABILITY = prefix('CLEAR_DEVICES_AVAILABILITY');
