import * as constants from 'data/constants/subscriptions';

export const validateCSV = (values, actions) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.VALIDATE_START });

  try {
    const data = new FormData();
    Object.entries(values).forEach(([name, value]) => {
      data.append(name, value);
    });

    const res = await api.post('/subscriptions/imports/', data);

    if (res.status !== 201) throw new Error(res.statusText);

    dispatch({
      type: constants.VALIDATE_SUCCESS,
      payload: res.data,
    });

    actions.setSubmitting(false);
    actions.setStatus(true);
    actions.resetForm();
    return true;
  } catch (e) {
    console.error(e);
    actions.setErrors(e.response.data);
    dispatch({ type: constants.VALIDATE_FAILED });

    return false;
  }
};

export const clearValidation = () => ({ type: constants.VALIDATE_CLEAR });

export const execute = (subscriptionUploadId, onError) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.EXECUTE_START });

  try {
    const res = await api.patch(`/subscriptions/imports/${subscriptionUploadId}/execute/`);

    if (res.status !== 200) throw new Error(res.statusText);

    dispatch({ type: constants.EXECUTE_SUCCESS });

    return true;
  } catch (e) {
    console.error(e);
    onError(e.response.data);
    dispatch({ type: constants.EXECUTE_FAILED });

    return false;
  }
};

export const clearExecution = () => ({ type: constants.EXECUTE_CLEAR });
