import { fromJS } from 'immutable';
import * as constants from 'data/constants/systems';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('system');
export const listLoadingProgress = new LoadingProgress('systemList');
export const itemsPagination = new Pagination('systemList');

const mergeData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { systems },
    } = payload;
    newState.mergeIn(['entities'], fromJS(systems));
  });

const loadList = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const clear = state =>
  state.withMutations(newState => {
    listLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);

    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.CLEAR:
      return clear(state, action);
    default:
      return state;
  }
};
