import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, createTransform, persistReducer } from 'redux-persist';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import Immutable, { isImmutable } from 'immutable';
import { baseURL } from 'config';
import createFilter from 'redux-persist-transform-filter-immutable';

import { normalize } from 'normalizr';
import Serialize from 'remotedev-serialize';
import * as Sentry from '@sentry/react';
// import createSentryMiddleware from 'redux-sentry-middleware';

import * as schema from './utils/schemas';
import API from './api';

import reducers, { entities } from './reducers';

const immutableReconciler = (inboundState, originalState, reducedState) => {
  const newState = { ...reducedState };

  Object.keys(reducedState).forEach(reducerKey => {
    if (reducerKey === '_persist' || originalState[reducerKey] !== reducedState[reducerKey]) {
      return;
    }
    newState[reducerKey] = isImmutable(newState[reducerKey])
      ? newState[reducerKey].merge(inboundState[reducerKey])
      : Object.assign(newState[reducerKey], inboundState[reducerKey]);
  });

  return newState;
};

const immutableTransform = (config = {}) => {
  const serializer = Serialize.immutable(Immutable, config.records);
  return createTransform(serializer.stringify, serializer.parse, config);
};

const createTransforms = () => Object.keys(entities).map(reducer => createFilter(reducer, ['entities']));

/* eslint-disable */
export let apiInstance;
/* eslint-enable */

const storeCreator = ({ storage, language }) => {
  const composeEnhancers = composeWithDevTools({
    serialize: {
      immutable: Immutable,
    },
  });

  const persistConfig = {
    key: 'opu/root',
    transforms: [...createTransforms(), createFilter('session', ['refresh']), immutableTransform()],
    storage,
    stateReconciler: immutableReconciler,
    version: '0.0.5',
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

  const thunkExtraArguments = {
    schema,
    normalize,
  };

  const actionTimestamp = () => next => action =>
    next({
      ...action,
      timestamp: new Date(),
    });

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(thunk.withExtraArgument(thunkExtraArguments)),
      applyMiddleware(actionTimestamp),
      // applyMiddleware(
      //   createSentryMiddleware(Sentry, {
      //     // Optionally pass some options here.
      //   }),
      // ),
    ),
  );

  const headers = { 'Accept-Language': 'en-US' };

  apiInstance = new API(store, { baseURL, headers });

  thunkExtraArguments.api = apiInstance;

  const persistor = persistStore(store);
  return { store, persistor };
};

export default storeCreator;
