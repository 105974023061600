import { fromJS } from 'immutable';
import * as constants from 'data/constants/suppliers';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('suppliers');
export const listLoadingProgress = new LoadingProgress('suppliersList');
export const itemsPagination = new Pagination('suppliersList');

export const sitesItemsPagination = new Pagination('supplierSites');
export const sitesListLoadingProgress = new LoadingProgress('supplierSites');

const mergeData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { suppliers },
    } = payload;
    newState.mergeIn(['entities'], fromJS(suppliers));
  });

const loadList = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const mergeSitesData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { sites },
    } = payload;
    newState.mergeIn(['sites'], fromJS(sites));
  });

const loadSitesList = (state, action) =>
  state.withMutations(newState => {
    mergeSitesData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    sitesItemsPagination.set(newState, total, perPage, fromJS(result));
    sitesListLoadingProgress.setLoaded(newState);
  });

const clearSites = state =>
  state.withMutations(newState => {
    newState.setIn(['sites'], fromJS({}));
    sitesItemsPagination.clear(newState);
    sitesListLoadingProgress.clear(newState);
  });

const clearList = state =>
  state.withMutations(newState => {
    newState.setIn(['entities'], fromJS({}));
    itemsPagination.clear(newState);
    listLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
  sites: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);

    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.SITES_LIST_LOAD_START:
      return sitesListLoadingProgress.setLoading(state);
    case constants.SITES_LIST_LOAD_FAILED:
      return sitesListLoadingProgress.setLoadFailed(state);
    case constants.SITES_LIST_LOAD_SUCCESS:
      return loadSitesList(state, action);

    case constants.CLEAR_LIST:
      return clearList(state, action);

    case constants.CLEAR_SITES:
      return clearSites(state, action);

    default:
      return state;
  }
};
