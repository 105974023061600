import { fromJS, List } from 'immutable';
import * as constants from 'data/constants/recommendations';
import LoadingProgress from 'data/utils/reducers/loading';

export const listLoadingProgress = new LoadingProgress('airspaceList');
export const siteEpisodesLoadingProgress = new LoadingProgress('siteEpisodes');
export const siteEpisodeDaysLoadingProgress = new LoadingProgress('episodeDays');

const clearSingle = state =>
  state.withMutations(newState => {
    siteEpisodesLoadingProgress.clear(newState);
  });

const mergeEpisodesData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { episodes },
    } = payload;

    newState.mergeIn(['episodesEntities'], fromJS(episodes));
  });

const loadSiteEpisodes = (state, action) =>
  state.withMutations(newState => {
    mergeEpisodesData(newState, action.payload);
    newState.set('siteEpisodes', fromJS(action.payload.result));
    siteEpisodesLoadingProgress.setLoaded(newState);
  });

const mergeEpisodeDaysData = (state, action) =>
  state.withMutations(newState => {
    const {
      episodeId,
      payload: {
        result,
        entities: { episodeDays },
      },
    } = action;
    const prevDays = state.get('episodeDays') || List();
    const newDays = result.filter(day => !prevDays.includes(day));
    newState.set('episodeDays', prevDays.concat(fromJS(newDays)));
    newState.setIn(['episodesEntities', episodeId, 'episodeDays'], result);
    newState.mergeIn(['episodeDaysEntities'], fromJS(episodeDays));
  });

const loadEpisodeDays = (state, action) =>
  state.withMutations(newState => {
    mergeEpisodeDaysData(newState, action);
    siteEpisodeDaysLoadingProgress.setLoaded(newState);
  });

const clear = state =>
  state.withMutations(newState => {
    clearSingle(newState);
    listLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  siteEpisodes: [],
  episodesEntities: {},
  episodeDays: [],
  episodeDaysEntities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.CLEAR_SINGLE:
      return clearSingle(state);
    case constants.CLEAR:
      return clear(state);

    case constants.SITE_EPISODES_LOAD_START:
      return siteEpisodesLoadingProgress.setLoading(state);
    case constants.SITE_EPISODES_LOAD_FAILED:
      return siteEpisodesLoadingProgress.setLoadFailed(state);
    case constants.SITE_EPISODES_LOAD_SUCCESS:
      return loadSiteEpisodes(state, action);

    case constants.EPISODE_DAYS_LOAD_START:
      return siteEpisodeDaysLoadingProgress.setLoading(state);
    case constants.EPISODE_DAYS_LOAD_FAILED:
      return siteEpisodeDaysLoadingProgress.setLoadFailed(state);
    case constants.EPISODE_DAYS_LOAD_SUCCESS:
      return loadEpisodeDays(state, action);

    default:
      return state;
  }
};
