import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Header from 'components/app/header';

import circles from 'public/concentric-circles.svg';
import s from './styles.scss';

const LayoutShared = ({ className, children }) => (
  <div className={cx(s.layout, className)}>
    <Header className={s.layoutHeader} />
    <div className={s.layoutMain}>
      <img src={circles} alt="decoration_circle_1" className={cx(s.layoutMainConcentric, s.layoutMainConcentricLeft)} />
      <img
        src={circles}
        alt="decoration_circle_2"
        className={cx(s.layoutMainConcentric, s.layoutMainConcentricRight)}
      />
      <div className={s.layoutMainContent}>{children}</div>
    </div>
  </div>
);

LayoutShared.defaultProps = {
  className: null,
  children: null,
};

LayoutShared.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default LayoutShared;
