import { fromJS } from 'immutable';
import * as constants from 'data/constants/profile';
import LoadingProgress from 'data/utils/reducers/loading';

export const loadingProgress = new LoadingProgress('profile');
export const loadingFaqProgress = new LoadingProgress('faq');
export const languagesLoadingProgress = new LoadingProgress('languages');
export const loadingCountriesProgress = new LoadingProgress('countries');
export const settingsLoadingProgress = new LoadingProgress('settings');

const loadProfile = (state, action) =>
  state.withMutations(newState => {
    const user = action.payload;
    loadingProgress.setLoaded(newState);
    newState.set('profile', fromJS(user));
  });

const loadSettings = (state, action) =>
  state.withMutations(newState => {
    const settings = action.payload;
    settingsLoadingProgress.setLoaded(newState);
    newState.set('settings', fromJS(settings));
  });

const loadLanguages = (state, action) =>
  state.withMutations(newState => {
    const languages = action.payload;
    languagesLoadingProgress.setLoaded(newState);
    newState.set('languages', fromJS(languages));
  });

const profileFaqLoaded = (state, action) =>
  state.withMutations(newState => {
    const data = action.payload;
    loadingFaqProgress.setLoaded(newState);
    newState.set('faq', fromJS(data));
  });

const initialState = fromJS({});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadProfile(state, action);

    case constants.PROFILE_LOAD_START:
    case constants.UPDATE_START:
      return settingsLoadingProgress.setLoading(state);
    case constants.PROFILE_LOAD_FAILED:
    case constants.UPDATE_FAILED:
      return settingsLoadingProgress.setLoadFailed(state);
    case constants.PROFILE_LOAD_SUCCESS:
    case constants.UPDATE_SUCCESS:
      return loadSettings(state, action);
    case constants.FAQ_LOAD_SUCCESS:
      return profileFaqLoaded(state, action);

    case constants.LANGUAGES_LOAD_START:
      return languagesLoadingProgress.setLoading(state);
    case constants.LANGUAGES_LOAD_FAILED:
      return languagesLoadingProgress.setLoadFailed(state);
    case constants.LANGUAGES_LOAD_SUCCESS:
      return loadLanguages(state, action);

    default:
      return state;
  }
};
