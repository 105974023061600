import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as ImmutablePropTypes from 'react-immutable-proptypes';
import cx from 'classnames';
import { isDemo } from 'config';
import { baseAppPath } from 'constants/urls';
import Breadcrumbs from 'components/core/breadcrumbs';
import Icon from 'components/core/icon';
import Profile from './profile';
import s from './styles.scss';

const AppHeader = ({ profile }) => {
  const name = isDemo ? 'OPU DASHBOARD <br> DEMO' : 'OPU <br> DASHBOARD';
  return (
    <div className={cx(s.header, isDemo && s.headerDemo)}>
      <Link to={baseAppPath} className={s.link}>
        <Icon className={s.linkLogo} icon={Icon.icons.logo} />
        <span className={s.linkName} dangerouslySetInnerHTML={{ __html: name }} />
      </Link>
      {profile && <Breadcrumbs className={s.breadcrumbs} />}
      {profile && <Profile profile={profile} />}
    </div>
  );
};

AppHeader.propTypes = {
  profile: ImmutablePropTypes.mapContains(PropTypes.shape({})),
};

AppHeader.defaultProps = {
  profile: null,
};

export default AppHeader;
