import React from 'react';
import css from 'classnames';
import PropTypes from 'prop-types';

import s from './styles.scss';

const TransparentPreloader = ({ className, style }) => (
  <div className={css(s.preloader, s.preloaderTransparent, className)} style={style} />
);

TransparentPreloader.defaultProps = {
  style: null,
  className: null,
};

TransparentPreloader.propTypes = {
  /* eslint-disable-next-line */
  style: PropTypes.object,
  className: PropTypes.string,
};

export default TransparentPreloader;
