import { combineReducers } from 'redux';

import session from './session';
import api from './api';

import sites from './sites';
import monitors from './monitors';
import gateways from './gateways';
import systems from './systems';
import airspaces from './airspaces';
import profile from './profile';
import replacements from './replacements';
import users from './users';
import exportsReducer from './exports';
import suppliers from './suppliers';
import recommendations from './recommendations';
import subscriptions from './subscriptions';

export const entities = {
  sites,
  monitors,
  gateways,
  systems,
  airspaces,
  profile,
  replacements,
  users,
  exportsReducer,
  suppliers,
  recommendations,
  subscriptions,
};

const generalReducer = combineReducers({
  session,
  api,
  ...entities,
});

const rootReducer = (state, action) => {
  return generalReducer(state, action);
};

export default rootReducer;
