import * as constants from 'data/constants/exports';
import { saveGeneralBlob } from 'data/utils/actions';
import * as selectors from 'data/selectors/exports';
import Axios from 'axios';

export const fetchList = (id, page, perPage, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get(`sites/${id}/exports/`, {
      params: { page, perPage, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.exportItem]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};

export const backgroundFetch = (id, page, perPage, orderingField, order) => async (
  dispatch,
  getState,
  { api, schema, normalize },
) => {
  if (selectors.getItemsListLoading(getState())) return;

  try {
    const res = await api.get(`sites/${id}/exports/`, {
      params: { page, perPage, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.exportItem]),
    };
    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
};

export const startExport = (values, actions) => async (dispatch, _, { api, normalize, schema }) => {
  dispatch({ type: constants.EXPORT_START_START });
  actions.setSubmitting(true);
  try {
    const { id, from, to } = values;

    const res = await api.post(`sites/${id}/exports/`, { start_date: from, end_date: to });

    actions.setSubmitting(false);
    actions.setStatus(true);
    dispatch({ type: constants.EXPORT_START_SUCCESS, payload: normalize(res.data, schema.exportItem) });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e.response?.data);
    // dispatch({ type: constants.EXPORT_START_FAILED });
    if (e.response?.data) {
      actions.setErrors({ message: e.response.data });
    }
    actions.setSubmitting(false);

    return false;
  }
};

export const downloadExport = (siteId, exportId, fileType, name) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.LIST_DOWNLOAD_START });
  try {
    const type = fileType === 'excel' ? 'xlsx' : fileType;
    const res = await api.get(`sites/${siteId}/exports/${exportId}/`, {
      params: { file_type: type },
    });

    const authStorage = window.localStorage.getItem('auth');
    api.removeAuthorization();

    const { data: blob } = await Axios.get(res.data.download_url, {
      responseType: 'blob',
    });
    api.setAuthorization(authStorage);
    saveGeneralBlob(blob, name || 'exports', fileType);

    dispatch({
      type: constants.LIST_DOWNLOAD_SUCCESS,
    });

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_DOWNLOAD_FAILED });

    return e;
  }
};
