import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useTranslateFormErrors = (errors, touched, setFieldTouched) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.on('languageChanged', () => {
      Object.keys(errors).forEach(fieldName => {
        if (Object.keys(touched).includes(fieldName)) {
          setFieldTouched(fieldName);
        }
      });
    });
    return () => {
      i18n.off('languageChanged', () => {});
    };
  }, [errors]);
};

export const WithTranslateFormErrors = ({ errors, touched, setFieldTouched, children }) => {
  useTranslateFormErrors(errors, touched, setFieldTouched);
  return <>{children}</>;
};

WithTranslateFormErrors.propTypes = {
  errors: PropTypes.shape({}),
  touched: PropTypes.bool,
  setFieldTouched: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

WithTranslateFormErrors.defaultProps = {
  errors: null,
  touched: false,
};
