import { fromJS, Set } from 'immutable';
import * as constants from 'data/constants/sites';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('site');
export const listLoadingProgress = new LoadingProgress('siteList');
export const itemsPagination = new Pagination('siteList');
export const statusLoadingProgress = new LoadingProgress('siteStatus');
export const monitorsLoadingProgress = new LoadingProgress('siteMonitors');
export const monitorsListPagination = new Pagination('siteMonitors');
export const gatewaysLoadingProgress = new LoadingProgress('siteGateways');
export const gatewaysListPagination = new Pagination('siteGateways');
export const statsLoadingProgress = new LoadingProgress('sitesStats');

// Alerts & Notifications
export const alertsLoadingProgress = new LoadingProgress('siteAlerts');
export const alertsListPagination = new Pagination('siteAlerts');
export const notificationsLoadingProgress = new LoadingProgress('siteNotifications');
export const notificationsListPagination = new Pagination('siteNotifications');
export const pushNotificationsLoadingProgress = new LoadingProgress('sitePushNotifications');
export const pushNotificationsListPagination = new Pagination('sitePushNotifications');

// Airspaces
export const airspacesLoadingProgress = new LoadingProgress('siteAirspaces');
export const airspacesListPagination = new Pagination('siteAirspaces');

// Heatmap
export const heatmapLoadingProgress = new LoadingProgress('siteAirspaces');

// Sites map
export const mapListLoadingProgress = new LoadingProgress('siteMapList');

// Devices availability
export const devicesAvailabilityLoadingProgress = new LoadingProgress('siteDevicesAvailability');

// site systems
export const systemsLoadingProgress = new LoadingProgress('systems');
export const systemsCreationLoadingProgress = new LoadingProgress('systemsCreate');

export const diseaseHistoryLoadingProgress = new LoadingProgress('diseaseHistory');

const mergeData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { sites },
    } = payload;
    newState.mergeIn(['entities'], fromJS(sites));
  });

const loadList = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const loadStatus = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['status'], fromJS(action.payload));
    statusLoadingProgress.setLoaded(newState);
  });

const mergeMonitors = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { monitors },
    } = payload;
    newState.mergeIn(['monitors'], fromJS(monitors));
  });

const loadMonitors = (state, action) =>
  state.withMutations(newState => {
    mergeMonitors(newState, action.payload);
    const { result, total, perPage } = action.payload;
    monitorsListPagination.set(newState, total, perPage, fromJS(result));
    monitorsLoadingProgress.setLoaded(newState);
  });

const mergeGateways = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { gateways },
    } = payload;
    newState.mergeIn(['gateways'], fromJS(gateways));
  });

const loadGateways = (state, action) =>
  state.withMutations(newState => {
    mergeGateways(newState, action.payload);
    const { result, total, perPage } = action.payload;
    gatewaysListPagination.set(newState, total, perPage, fromJS(result));
    gatewaysLoadingProgress.setLoaded(newState);
  });

const loadStats = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['sitesStats'], fromJS(action.payload));
    statsLoadingProgress.setLoaded(newState);
  });

const mergeAlerts = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { alerts },
    } = payload;
    newState.mergeIn(['alerts'], fromJS(alerts));
  });

const loadAlerts = (state, action) =>
  state.withMutations(newState => {
    mergeAlerts(newState, action.payload);
    const { result, total, perPage } = action.payload;
    alertsListPagination.set(newState, total, perPage, fromJS(result));
    alertsLoadingProgress.setLoaded(newState);
  });

const mergeNotifications = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { notifications },
    } = payload;
    newState.mergeIn(['notifications'], fromJS(notifications));
  });

const loadNotifications = (state, action) =>
  state.withMutations(newState => {
    mergeNotifications(newState, action.payload);
    const { result, total, perPage } = action.payload;
    notificationsListPagination.set(newState, total, perPage, fromJS(result));
    notificationsLoadingProgress.setLoaded(newState);
  });

const mergePushNotifications = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { pushNotifications },
    } = payload;
    newState.mergeIn(['pushNotifications'], fromJS(pushNotifications));
  });

const loadPushNotifications = (state, action) =>
  state.withMutations(newState => {
    mergePushNotifications(newState, action.payload);
    const { result, total, perPage } = action.payload;
    pushNotificationsListPagination.set(newState, total, perPage, fromJS(result));
    pushNotificationsLoadingProgress.setLoaded(newState);
  });

const mergeAirspaces = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { airspaces },
    } = payload;
    newState.mergeIn(['airspaces'], fromJS(airspaces));
  });

const loadAirspaces = (state, action) =>
  state.withMutations(newState => {
    mergeAirspaces(newState, action.payload);
    const { result, total, perPage } = action.payload;
    airspacesListPagination.set(newState, total, perPage, fromJS(result));
    airspacesLoadingProgress.setLoaded(newState);
  });

const loadHeatmap = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data },
    } = action;
    newState.setIn(['heatmap'], fromJS(data));
    heatmapLoadingProgress.setLoaded(newState);
  });

const loadDevicesAvailability = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: { data },
    } = action;
    newState.setIn(['devicesAvailability'], fromJS(data));
    devicesAvailabilityLoadingProgress.setLoaded(newState);
  });

const loadMapList = (state, action) =>
  state.withMutations(newState => {
    const { payload } = action;
    newState.setIn(['sitesMap'], fromJS(payload));
    mapListLoadingProgress.setLoaded(newState);
  });

const clear = state =>
  state.withMutations(newState => {
    listLoadingProgress.clear(newState);
  });

const clearSingleStatus = state =>
  state.withMutations(newState => {
    statusLoadingProgress.clear(newState);
  });

const clearSingle = state =>
  state.withMutations(newState => {
    loadingProgress.clear(newState);
    clearSingleStatus(newState);
  });

const clearStats = state =>
  state.withMutations(newState => {
    statsLoadingProgress.clear(newState);
  });

const clearHeatmap = state =>
  state.withMutations(newState => {
    heatmapLoadingProgress.clear(newState);
  });

const clearDevicesAvailability = state =>
  state.withMutations(newState => {
    devicesAvailabilityLoadingProgress.clear(newState);
  });

const loadSystems = (state, action) =>
  state.withMutations(newState => {
    const {
      payload: {
        entities: { systems },
        result,
      },
    } = action;
    newState.setIn(['systems'], fromJS(systems));
    newState.setIn(['systemsList'], fromJS(result));
    systemsLoadingProgress.setLoaded(newState);
  });

const loadDiseaseHistory = (state, action) =>
  state.withMutations(newState => {
    newState.set('diseaseHistory', fromJS(action.payload));
    diseaseHistoryLoadingProgress.setLoaded(newState);
  });

const filterStatus = (state, action) => state.setIn(['filter', 'status'], action.payload);

const filterType = (state, action) => state.setIn(['filter', 'type'], Set(action.payload));

const filterSearch = (state, action) => state.setIn(['filter', 'search'], action.payload);

const initialState = fromJS({
  entities: {},
  status: {},
  monitors: {},
  sitesStats: {},
  sitesMap: {},
  filter: {
    status: Set([]),
    type: Set([]),
    search: null,
  },
  diseaseHistory: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
    case constants.INFO_UPDATE_SUCCESS:
      return loadEntity(state, action);

    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.MAP_LIST_LOAD_START:
      return mapListLoadingProgress.setLoading(state);
    case constants.MAP_LIST_LOAD_FAILED:
      return mapListLoadingProgress.setLoadFailed(state);
    case constants.MAP_LIST_LOAD_SUCCESS:
      return loadMapList(state, action);

    case constants.STATUS_LOAD_START:
      return statusLoadingProgress.setLoading(state);
    case constants.STATUS_LOAD_FAILED:
      return statusLoadingProgress.setLoadFailed(state);
    case constants.STATUS_LOAD_SUCCESS:
      return loadStatus(state, action);

    case constants.DEVICE_LIST_LOAD_START:
      return monitorsLoadingProgress.setLoading(state);
    case constants.DEVICE_LIST_LOAD_FAILED:
      return monitorsLoadingProgress.setLoadFailed(state);
    case constants.DEVICE_LIST_LOAD_SUCCESS:
      return loadMonitors(state, action);

    case constants.GATEWAY_LIST_LOAD_START:
      return gatewaysLoadingProgress.setLoading(state);
    case constants.GATEWAY_LIST_LOAD_FAILED:
      return gatewaysLoadingProgress.setLoadFailed(state);
    case constants.GATEWAY_LIST_LOAD_SUCCESS:
      return loadGateways(state, action);

    case constants.STATS_LOAD_START:
      return statsLoadingProgress.setLoading(state);
    case constants.STATS_LOAD_FAILED:
      return statsLoadingProgress.setLoadFailed(state);
    case constants.STATS_LOAD_SUCCESS:
      return loadStats(state, action);

    case constants.ALERTS_LIST_LOAD_START:
      return alertsLoadingProgress.setLoading(state);
    case constants.ALERTS_LIST_LOAD_FAILED:
      return alertsLoadingProgress.setLoadFailed(state);
    case constants.ALERTS_LIST_LOAD_SUCCESS:
      return loadAlerts(state, action);

    case constants.NOTIFICATIONS_LIST_LOAD_START:
      return notificationsLoadingProgress.setLoading(state);
    case constants.NOTIFICATIONS_LIST_LOAD_FAILED:
      return notificationsLoadingProgress.setLoadFailed(state);
    case constants.NOTIFICATIONS_LIST_LOAD_SUCCESS:
      return loadNotifications(state, action);

    case constants.PUSH_NOTIFICATIONS_LIST_LOAD_START:
      return pushNotificationsLoadingProgress.setLoading(state);
    case constants.PUSH_NOTIFICATIONS_LIST_LOAD_FAILED:
      return pushNotificationsLoadingProgress.setLoadFailed(state);
    case constants.PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS:
      return loadPushNotifications(state, action);

    case constants.AIRSPACES_LIST_LOAD_START:
      return airspacesLoadingProgress.setLoading(state);
    case constants.AIRSPACES_LIST_LOAD_FAILED:
      return airspacesLoadingProgress.setLoadFailed(state);
    case constants.AIRSPACES_LIST_LOAD_SUCCESS:
      return loadAirspaces(state, action);

    case constants.SITE_HEATMAP_LOAD_START:
      return heatmapLoadingProgress.setLoading(state);
    case constants.SITE_HEATMAP_LOAD_FAILED:
      return heatmapLoadingProgress.setLoadFailed(state);
    case constants.SITE_HEATMAP_LOAD_SUCCESS:
      return loadHeatmap(state, action);

    case constants.DEVICES_AVAILABILITY_LOAD_START:
      return devicesAvailabilityLoadingProgress.setLoading(state);
    case constants.DEVICES_AVAILABILITY_LOAD_FAILED:
      return devicesAvailabilityLoadingProgress.setLoadFailed(state);
    case constants.DEVICES_AVAILABILITY_LOAD_SUCCESS:
      return loadDevicesAvailability(state, action);

    case constants.SYSTEMS_LOAD_START:
      return systemsLoadingProgress.setLoading(state);
    case constants.SYSTEMS_LOAD_FAILED:
      return systemsLoadingProgress.setLoadFailed(state);
    case constants.SYSTEMS_LOAD_SUCCESS:
      return loadSystems(state, action);

    case constants.SYSTEMS_CREATION_START:
      return systemsCreationLoadingProgress.setLoading(state);
    case constants.SYSTEMS_CREATION_FAILED:
      return systemsCreationLoadingProgress.setLoadFailed(state);
    case constants.SYSTEMS_CREATION_SUCCESS:
      return systemsCreationLoadingProgress.setLoaded(state);

    case constants.FILTER_TYPE:
      return filterType(state, action);
    case constants.FILTER_STATUS:
      return filterStatus(state, action);
    case constants.FILTER_SEARCH:
      return filterSearch(state, action);

    case constants.DISEASE_HISTORY_LOAD_START:
      return diseaseHistoryLoadingProgress.setLoading(state);
    case constants.DISEASE_HISTORY_LOAD_SUCCESS:
      return loadDiseaseHistory(state, action);
    case constants.DISEASE_HISTORY_LOAD_FAILED:
      return diseaseHistoryLoadingProgress.setLoadFailed(state);

    case constants.CLEAR:
      return clear(state, action);

    case constants.CLEAR_SINGLE:
      return clearSingle(state, action);

    case constants.CLEAR_STATS:
      return clearStats(state, action);

    case constants.CLEAR_HEATMAP:
      return clearHeatmap(state, action);

    case constants.CLEAR_DEVICES_AVAILABILITY:
      return clearDevicesAvailability(state, action);

    default:
      return state;
  }
};
