export const fontFamily = {
  label: 'Roboto',
  regular: 'Roboto-Regular',
  medium: 'Roboto-Medium',
  bold: 'Roboto-Bold',
  black: 'Roboto-Black',
};

export const fontWeight = {
  regular: '400',
  medium: '500',
  bold: '700',
};

export const fontSize = {
  xs: 12,
  s: 14,
  m: 16,
  l: 18,
  xl: 20,
};
