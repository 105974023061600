const prefix = type => `opu/airspaces/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const RHS_DATA_LOAD_START = prefix('RHS_DATA_LOAD_START');
export const RHS_DATA_LOAD_SUCCESS = prefix('RHS_DATA_LOAD_SUCCESS');
export const RHS_DATA_LOAD_FAILED = prefix('RHS_DATA_LOAD_FAILED');

export const TEMPERATURE_DATA_LOAD_START = prefix('TEMPERATURE_DATA_LOAD_START');
export const TEMPERATURE_DATA_LOAD_SUCCESS = prefix('TEMPERATURE_DATA_LOAD_SUCCESS');
export const TEMPERATURE_DATA_LOAD_FAILED = prefix('TEMPERATURE_DATA_LOAD_FAILED');

export const HUMIDITY_DATA_LOAD_START = prefix('HUMIDITY_DATA_LOAD_START');
export const HUMIDITY_DATA_LOAD_SUCCESS = prefix('HUMIDITY_DATA_LOAD_SUCCESS');
export const HUMIDITY_DATA_LOAD_FAILED = prefix('HUMIDITY_DATA_LOAD_FAILED');

export const CLEAR_RHS_TEMPERATURE = prefix('CLEAR_RHS_TEMPERATURE');

export const MONITORS_LIST_LOAD_START = prefix('MONITORS_LIST_LOAD_START');
export const MONITORS_LIST_LOAD_SUCCESS = prefix('MONITORS_LIST_LOAD_SUCCESS');
export const MONITORS_LIST_LOAD_FAILED = prefix('MONITORS_LIST_LOAD_FAILED');

export const MONITORS_RHS_DATA_LOAD_START = prefix('MONITORS_RHS_DATA_LOAD_START');
export const MONITORS_RHS_DATA_LOAD_SUCCESS = prefix('MONITORS_RHS_DATA_LOAD_SUCCESS');
export const MONITORS_RHS_DATA_LOAD_FAILED = prefix('MONITORS_RHS_DATA_LOAD_FAILED');

export const MONITORS_TEMPERATURE_DATA_LOAD_START = prefix('MONITORS_TEMPERATURE_DATA_LOAD_START');
export const MONITORS_TEMPERATURE_DATA_LOAD_SUCCESS = prefix('MONITORS_TEMPERATURE_DATA_LOAD_SUCCESS');
export const MONITORS_TEMPERATURE_DATA_LOAD_FAILED = prefix('MONITORS_TEMPERATURE_DATA_LOAD_FAILED');

export const MONITORS_HUMIDITY_DATA_LOAD_START = prefix('MONITORS_HUMIDITY_DATA_LOAD_START');
export const MONITORS_HUMIDITY_DATA_LOAD_SUCCESS = prefix('MONITORS_HUMIDITY_DATA_LOAD_SUCCESS');
export const MONITORS_HUMIDITY_DATA_LOAD_FAILED = prefix('MONITORS_HUMIDITY_DATA_LOAD_FAILED');

export const MONITORS_HEATMAP_LOAD_START = prefix('MONITORS_HEATMAP_LOAD_START');
export const MONITORS_HEATMAP_LOAD_SUCCESS = prefix('MONITORS_HEATMAP_LOAD_SUCCESS');
export const MONITORS_HEATMAP_LOAD_FAILED = prefix('MONITORS_HEATMAP_LOAD_FAILED');

export const AIRSPACE_HEATMAP_LOAD_START = prefix('AIRSPACE_HEATMAP_LOAD_START');
export const AIRSPACE_HEATMAP_LOAD_SUCCESS = prefix('AIRSPACE_HEATMAP_LOAD_SUCCESS');
export const AIRSPACE_HEATMAP_LOAD_FAILED = prefix('AIRSPACE_HEATMAP_LOAD_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
