import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '../text';
import s from './styles.scss';

const GeneralFormError = ({ error, className }) =>
  error ? (
    <div className={s.error}>
      <Text className={cx(s.errorText, className)}>{error}</Text>
    </div>
  ) : null;

GeneralFormError.propTypes = {
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
};

GeneralFormError.defaultProps = {
  className: null,
};

export default GeneralFormError;
