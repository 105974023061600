import LoadingProgress from 'data/utils/reducers/loading';
import { fromJS } from 'immutable';
import * as constants from 'data/constants/subscriptions';

export const validationProgress = new LoadingProgress('subscriptionsValidation');
export const executionProgress = new LoadingProgress('subscriptionsExecution');

const loadValidation = (state, action) =>
  state.withMutations(newState => {
    const validation = action.payload;
    validationProgress.setLoaded(newState);
    newState.set('validation', fromJS(validation));
  });

const clearValidation = state =>
  state.withMutations(newState => {
    validationProgress.clear(newState);
    newState.set('validation', null);
  });

const initialState = fromJS({});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.VALIDATE_START:
      return validationProgress.setLoading(state);
    case constants.VALIDATE_FAILED:
      return validationProgress.setLoadFailed(state);
    case constants.VALIDATE_SUCCESS:
      return loadValidation(state, action);
    case constants.VALIDATE_CLEAR:
      return clearValidation(state);

    case constants.EXECUTE_START:
      return executionProgress.setLoading(state);
    case constants.EXECUTE_FAILED:
      return executionProgress.setLoadFailed(state);
    case constants.EXECUTE_SUCCESS:
      return executionProgress.setLoaded(state);
    case constants.EXECUTE_CLEAR:
      return executionProgress.clear(state);

    default:
      return state;
  }
};
