import * as constants from 'data/constants/gateways';
import { saveBlob } from 'data/utils/actions';

export const fetchList = (page, perPage, search, systemId, online, orderingField, order) => async (
  dispatch,
  _,
  { api, schema, normalize },
) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get('gateways/', {
      params: { page, perPage, search, system_id: systemId, online, ordering_field: orderingField, order },
    });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.gateway]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};

export const fetchSingle = id => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.LOAD_START });
  try {
    const res = await api.get(`/gateways/${id}/`);

    dispatch({
      type: constants.LOAD_SUCCESS,
      payload: normalize(res.data, schema.gateway),
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.LOAD_FAILED });
  }
};

export const downloadList = (fileType, search, systemId, online) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.LIST_DOWNLOAD_START });
  try {
    const res = await api.get('gateways/download/', {
      params: { file_type: fileType, search, system_id: systemId, online },
      responseType: 'arraybuffer',
    });

    dispatch({
      type: constants.LIST_DOWNLOAD_SUCCESS,
    });

    saveBlob(res.data, 'gateways-list', fileType);

    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_DOWNLOAD_FAILED });

    return e;
  }
};

export const fetchStats = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.STATS_LOAD_START });
  try {
    const res = await api.get('gateways/stats/');

    dispatch({
      type: constants.STATS_LOAD_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.STATS_LOAD_FAILED });

    return e;
  }
};

export const fetchStatusTimeline = (id, timeframe) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.STATUS_TIMELINE_LOAD_START });
  try {
    const { from, to } = timeframe;
    const res = await api.get(`gateways/${id}/status-timeline/`, {
      params: { start_date: from, end_date: to },
    });

    dispatch({
      type: constants.STATUS_TIMELINE_LOAD_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.STATUS_TIMELINE_LOAD_FAILED });
  }
};

export const clearStats = () => ({ type: constants.CLEAR_STATS });
