const prefix = type => `opu/suppliers/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const SITES_LIST_LOAD_START = prefix('SITES_LIST_LOAD_START');
export const SITES_LIST_LOAD_SUCCESS = prefix('SITES_LIST_LOAD_SUCCESS');
export const SITES_LIST_LOAD_FAILED = prefix('SITES_LIST_LOAD_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_LIST = prefix('CLEAR_LIST');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');

export const CLEAR_SITES = prefix('CLEAR_SITES');
