const prefix = type => `opu/recommendations/${type}`;

export const SITE_EPISODES_LOAD_START = prefix('SITE_EPISODES_LOAD_START');
export const SITE_EPISODES_LOAD_SUCCESS = prefix('SITE_EPISODES_LOAD_SUCCESS');
export const SITE_EPISODES_LOAD_FAILED = prefix('SITE_EPISODES_LOAD_FAILED');

export const EPISODE_DAYS_LOAD_START = prefix('EPISODE_DAYS_LOAD_START');
export const EPISODE_DAYS_LOAD_SUCCESS = prefix('EPISODE_DAYS_LOAD_SUCCESS');
export const EPISODE_DAYS_LOAD_FAILED = prefix('EPISODE_DAYS_LOAD_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
