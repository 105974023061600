import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  itemsPagination,
  suppliersListLoadingProgress,
  userRolesListLoadingProgress,
  rolesLoadingProgress,
} from 'data/reducers/users';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.users;

export const getEntities = state => getState(state).get('entities');

export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (users, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.user], new Map({ users })),
);

export const getItemsTotal = state => itemsPagination.getTotalItems(getState(state));

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = state => loadingProgress.getLoaded(getState(state));
export const getItemLoading = state => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = state => loadingProgress.getLoadFailed(getState(state));

// Suppliers

export const getSuppliers = state => getState(state).get('suppliers');

export const getSuppliersLoaded = state => suppliersListLoadingProgress.getLoaded(getState(state));
export const getSuppliersLoading = state => suppliersListLoadingProgress.getLoading(getState(state));
export const getSuppliersLoadFailed = state => suppliersListLoadingProgress.getLoadFailed(getState(state));

// User roles

export const getUserRoles = state => getState(state).get('userRoles');

export const getUserRolesLoaded = state => userRolesListLoadingProgress.getLoaded(getState(state));
export const getUserRolesLoading = state => userRolesListLoadingProgress.getLoading(getState(state));
export const getUserRolesLoadFailed = state => userRolesListLoadingProgress.getLoadFailed(getState(state));

// Roles

export const getRoles = state => getState(state).get('roles');

export const getRolesLoaded = state => rolesLoadingProgress.getLoaded(getState(state));
export const getRolesLoading = state => rolesLoadingProgress.getLoading(getState(state));
export const getRolesLoadFailed = state => rolesLoadingProgress.getLoadFailed(getState(state));
