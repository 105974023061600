import * as constants from 'data/constants/session';
import * as selectors from 'data/selectors/session';

import { externalBaseUrl } from 'config';

export const fetchSession = (credentials, formik) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.LOGIN });
  formik.setSubmitting(true);
  try {
    const res = await api.post('/tokens/', credentials);
    api.setAuthorization(res.data.access);
    formik.setSubmitting(false);
    dispatch({
      type: constants.LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    });
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LOGIN_FAILED });
    formik.setSubmitting(false);
    formik.setErrors({ message: e.detail });
    if (e.response?.data) {
      formik.setErrors({ message: e.response.data.detail });
    }
  }
};

export const logout = () => (dispatch, __, { api }) => {
  api.removeAuthorization();
  return dispatch({ type: constants.LOGOUT });
};

export const refreshSession = () => async (dispatch, getState, { api }) => {
  const refresh = selectors.getRefresh(getState());
  if (!refresh) return false;

  dispatch({ type: constants.REFRESH });
  try {
    const res = await api.post(`${externalBaseUrl}/api/token/refresh/`, { refresh });
    api.setAuthorization(res.data.access);
    dispatch({
      type: constants.REFRESH_SUCCESS,
      payload: {
        ...res.data,
      },
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.REFRESH_FAILED });
    dispatch(logout());
    return false;
  }
};

export const resetPassword = (credentials, formik) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.RESET });
  formik.setSubmitting(true);
  try {
    const res = await api.post('tokens/password/recover/', credentials);
    formik.setSubmitting(false);
    dispatch({
      type: constants.RESET_SUCCESS,
    });
    if (res.data.status === 'OK') {
      formik.setStatus(true);
    } else formik.setErrors(res.data.status);
  } catch (e) {
    console.log(e);
    dispatch({ type: constants.RESET_FAILED });
    formik.setSubmitting(false);
    if (e.response?.data) {
      formik.setErrors(e.response.data);
    }
  }
};

export const recoverPassword = (credentials, formik) => async (dispatch, _, { api }) => {
  dispatch({ type: constants.RECOVER_CONFIRM });
  formik.setSubmitting(true);
  try {
    const res = await api.post('/tokens/password/recover/confirm/', credentials);
    formik.setSubmitting(false);
    dispatch({
      type: constants.RECOVER_CONFIRM_SUCCESS,
    });
    if (res.data.status === 'OK') {
      formik.setStatus(true);
    } else formik.setErrors(res.data.status);
  } catch (e) {
    console.log(e);
    dispatch({ type: constants.RECOVER_CONFIRM_FAILED });
    formik.setSubmitting(false);
    if (e.response?.data) {
      formik.setErrors(e.response.data);
    }
  }
};
