import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { PrimaryButton, Button, FlatButton } from 'ui';

import s from './styles.scss';

const createButtonLink = (Component, classModificator = null) => {
  const ButtonLink = ({ className, children, innerProps, ...props }) => {
    const { className: outerClassName, ...buttonInnerProps } = innerProps;
    return (
      <Link {...props} className={cx(s.buttonLink, className)}>
        <Component className={cx(classModificator, outerClassName)} {...buttonInnerProps}>
          {children}
        </Component>
      </Link>
    );
  };

  ButtonLink.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    // eslint-disable-next-line
    innerProps: PropTypes.object,
  };

  ButtonLink.defaultProps = {
    className: null,
    children: null,
    innerProps: {},
  };

  return ButtonLink;
};

export const PrimaryButtonLink = createButtonLink(PrimaryButton);
export const FlatButtonLink = createButtonLink(FlatButton);
export const ButtonLink = createButtonLink(Button);
