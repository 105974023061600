import React from 'react';
import PropTypes from 'prop-types';
import { colors, fontWeight, fontSize } from 'ui/components/constants/styles';

const Text = ({ size, weight, className, style, children, ...props }) => {
  const getStyles = () => {
    const styles = { ...style };
    if (size) styles.fontSize = size;
    if (weight) styles.fontWeight = weight;
    return styles;
  };

  return (
    <span className={className} style={getStyles()} {...props}>
      {children}
    </span>
  );
};

Text.weight = fontWeight;
Text.size = fontSize;
Text.color = colors;

Text.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(), PropTypes.array]),
  children: PropTypes.node.isRequired,
  weight: PropTypes.oneOf(Object.values(Text.weight)),
  size: PropTypes.oneOf(Object.values(Text.size)),
  color: PropTypes.oneOf(Object.values(Text.color)),
};

Text.defaultProps = {
  className: null,
  style: null,
  weight: Text.weight.regular,
  size: Text.size.xs,
  color: Text.color.primary,
};

export default Text;
