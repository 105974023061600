import { fromJS } from 'immutable';
import * as constants from 'data/constants/gateways';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('gateway');
export const listLoadingProgress = new LoadingProgress('gatewayList');
export const itemsPagination = new Pagination('gatewayList');

export const statsLoadingProgress = new LoadingProgress('gatewaysStats');

export const statusTimelineLoadingProgress = new LoadingProgress('gatewayStatusTimeline');

const mergeData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { gateways },
    } = payload;
    newState.mergeIn(['entities'], fromJS(gateways));
  });

const loadList = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const loadStats = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['gatewaysStats'], fromJS(action.payload));
    statsLoadingProgress.setLoaded(newState);
  });

const loadStatusTimeline = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['statusTimeline'], fromJS(action.payload));
    statusTimelineLoadingProgress.setLoaded(newState);
  });

const clear = state =>
  state.withMutations(newState => {
    listLoadingProgress.clear(newState);
  });

const clearStats = state =>
  state.withMutations(newState => {
    statsLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);

    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.STATS_LOAD_START:
      return statsLoadingProgress.setLoading(state);
    case constants.STATS_LOAD_FAILED:
      return statsLoadingProgress.setLoadFailed(state);
    case constants.STATS_LOAD_SUCCESS:
      return loadStats(state, action);

    case constants.STATUS_TIMELINE_LOAD_START:
      return statusTimelineLoadingProgress.setLoading(state);
    case constants.STATUS_TIMELINE_LOAD_FAILED:
      return statusTimelineLoadingProgress.setLoadFailed(state);
    case constants.STATUS_TIMELINE_LOAD_SUCCESS:
      return loadStatusTimeline(state, action);

    case constants.CLEAR:
      return clear(state, action);

    case constants.CLEAR_STATS:
      return clearStats(state, action);
    default:
      return state;
  }
};
