import * as Yup from 'yup';

export const LoginSchema = t =>
  Yup.object().shape({
    username: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .required(t('error_required', { what: t('email') })),
    password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .required(t('error_required', { what: t('password') })),
  });

export const ResetPasswordSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .required(t('error_required', { what: t('email') })),
  });

export const RecoverPasswordSchema = t =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .max(100, t('error_too_long'))
      .required(t('error_required', { what: t('password') })),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], t('error_match'))
      .required(t('error_required', { what: t('password_confirm') })),
  });

export const AddUserSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .max(150, t('error_too_long'))
      .required(t('error_required', { what: t('email') })),
    supplier: Yup.string()
      .required(t('error_required', { what: 'OPU' }))
      .nullable(),
    role: Yup.string()
      .required(t('error_required', { what: t('role') }))
      .nullable(),
  });

export const DeviceReplacementRequestSchema = () =>
  Yup.object().shape({
    // site: Yup.string().required(t('error_required', { what: t('site') })),
    // type: Yup.string().required(t('error_required', { what: t('type') })),
    // old: Yup.string().required(t('error_required', { what: t('old') })),
    // new_device: Yup.string(),
    // new_gateway: Yup.string(),
    // reason: Yup.string().required(t('error_required', { what: t('reason') })),
    // reason_description: Yup.string(),
    // comment: Yup.string(),
    // inspect_required: Yup.bool(),
  });

export const RegistrationSchema = t =>
  Yup.object().shape({
    first_name: Yup.string()
      .min(1, t('error_longer', { what: t('first_name'), count: '1' }))
      .max(30, t('error_too_long'))
      .required(t('error_required', { what: t('first_name') })),
    last_name: Yup.string()
      .min(1, t('error_longer', { what: t('last_name'), count: '1' }))
      .max(100, t('error_too_long'))
      .required(t('error_required', { what: t('last_name') })),
    email: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .max(150, t('error_too_long'))
      .required(t('error_required', { what: t('email') })),
    password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .max(100, t('error_too_long'))
      .required(t('error_required', { what: t('password') })),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], t('error_match'))
      .required(t('error_required', { what: t('password_confirm') })),
  });

export const SubscriptionValidationSchema = () =>
  Yup.object().shape({
    subscription_file: Yup.mixed().required('A CSV file is required'),
  });
