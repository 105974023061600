import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DarkInput, PrimaryButton, FieldWrapper } from 'ui';

import { Trans } from 'react-i18next';

import { signInPath } from 'constants/urls';
import { Form, Field } from 'formik';

import boehringerLogo from 'public/logo_st.png';
import stLogo from 'public/soundtalks-logo.png';

import Icon from 'components/core/icon';
import { FluidPreloader } from 'components/core/preloader';

import HeaderShared from './layout/header';
import FooterShared from './layout/footer';

import s from './styles.scss';

const RegisterForm = ({ className, isSubmitting, errors, handleSubmit }) => (
  <div className={cx(s.sharedForm, className, s.sharedFormSignIn)}>
    <HeaderShared className={s.sharedFormHeader}>
      <div className={s.sharedFormHeaderLogo} style={{ backgroundImage: `url(${boehringerLogo})` }} />
      <div className={s.sharedFormHeaderSt}>
        <div className={s.sharedFormHeaderStTitle}>
          <Trans i18nKey="powered_by" />
        </div>
        <div className={s.sharedFormHeaderStLogo} style={{ backgroundImage: `url(${stLogo})` }} />
      </div>
    </HeaderShared>
    <Form>
      <Field
        id="first_name"
        name="first_name"
        placeholder="First name"
        component={FieldWrapper}
        inputComponent={DarkInput}
        className={s.sharedFormField}
      />
      <Field
        id="last_name"
        name="last_name"
        placeholder="Last name"
        component={FieldWrapper}
        inputComponent={DarkInput}
        className={s.sharedFormField}
      />
      <Field
        disabled
        id="email"
        name="email"
        placeholder="Email"
        component={FieldWrapper}
        inputComponent={DarkInput}
        className={s.sharedFormField}
        error={errors.email || errors.message?.description}
      />

      <Field
        id="password"
        name="password"
        secureTextEntry
        placeholder="Password"
        component={FieldWrapper}
        inputComponent={DarkInput}
        className={s.sharedFormField}
      />
      <Field
        id="confirm_password"
        name="confirm_password"
        secureTextEntry
        placeholder="Confirm password"
        component={FieldWrapper}
        inputComponent={DarkInput}
        className={s.sharedFormField}
      />

      <PrimaryButton type="submit" className={s.sharedFormSubmit} onClick={handleSubmit}>
        {isSubmitting ? <FluidPreloader light /> : <Trans i18nKey="create_profile" />}
      </PrimaryButton>
    </Form>
    <FooterShared className={s.sharedFormFooterReset}>
      <Link to={signInPath} className={s.sharedFormFooterLink}>
        <Icon className={s.sharedFormFooterLinkIcon} icon={Icon.icons.arrowLeft} />
        Back
      </Link>
    </FooterShared>
  </div>
);

RegisterForm.defaultProps = {
  className: null,
  errors: {},
};

RegisterForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line
  errors: PropTypes.shape({}),
  isSubmitting: PropTypes.bool.isRequired,
};

export default RegisterForm;
