import * as constants from 'data/constants/profile';
import { externalBaseUrl } from 'config';

export const fetchSingle = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.LOAD_START });
  try {
    const res = await api.get(`${externalBaseUrl}/users/current/`);

    dispatch({
      type: constants.LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.LOAD_FAILED });
  }
};

export const fetchSettings = userId => async (dispatch, _, { api }) => {
  dispatch({ type: constants.PROFILE_LOAD_START });
  try {
    const res = await api.get(`${externalBaseUrl}/users/${userId}/settings/`);

    dispatch({
      type: constants.PROFILE_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.PROFILE_LOAD_FAILED });
  }
};

export const fetchFaq = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.FAQ_LOAD_START });
  try {
    const res = await api.get('https://backend.staging.soundtalksweb.com/pages/faq/');

    dispatch({
      type: constants.FAQ_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: constants.FAQ_LOAD_FAILED });
  }
};

export const fetchLanguages = () => async (dispatch, _, { api }) => {
  dispatch({ type: constants.LANGUAGES_LOAD_START });
  try {
    const res = await api.get(`${externalBaseUrl}/users/languages/`);

    dispatch({
      type: constants.LANGUAGES_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    dispatch({ type: constants.LANGUAGES_LOAD_FAILED });
  }
};

export const updateSingle = (values, actions) => async (dispatch, _, { api }) => {
  const { id, ...leftValues } = values;
  dispatch({ type: constants.UPDATE_START });
  try {
    const data = new FormData();
    Object.entries(leftValues).forEach(([name, value]) => {
      data.append(name, value);
    });

    if (leftValues.profile_picture && leftValues.profile_picture instanceof File === false) {
      data.delete('profile_picture');
    }

    const res = await api.patch(`${externalBaseUrl}/users/${id}/settings/`, data);
    if (res.status !== 200) throw new Error(res.statusText);
    actions.setSubmitting(false);
    actions.resetForm();
    actions.setStatus('success');
    dispatch({
      type: constants.UPDATE_SUCCESS,
      payload: res.data,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    actions.setErrors(e.response.data);
    dispatch({ type: constants.UPDATE_FAILED });

    return false;
  }
};
