import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

import arrowLeft from './icons/arrow-left.svg';
import arrowLeftWhite from './icons/arrow-left-white.svg';
import clock from './icons/clock.svg';
import clockAlert from './icons/clock-alert.svg';
import copy from './icons/copy.svg';
import alertCircle from './icons/alert-circle.svg';
import whiteAlertCircle from './icons/alert-circle-white.svg';
import message from './icons/message.svg';
import whiteMessage from './icons/white-message.svg';
import bell from './icons/bell.svg';
import whiteBell from './icons/white-bell.svg';
import sidepanelCollapse from './icons/sidepanel-collapse.svg';
import greenClose from './icons/green-close.svg';
import temperatureWarning from './icons/warning-thermal-shock.svg';
import temperatureAlert from './icons/alarm-thermal-shock.svg';
import humidityWarning from './icons/warning-heat-stress.svg';
import humidityAlert from './icons/alam-heat-stress.svg';
import calendar from './icons/calendar.svg';
import coughWarning from './icons/cough-warning.svg';
import coughAlert from './icons/cough-alert.svg';
import atSign from './icons/at-sign.svg';
import home from './icons/home.svg';
import search from './icons/search.svg';
import refresh from './icons/refresh.svg';
import cough from './icons/cough.svg';
import thermometer from './icons/thermometer.svg';
import drop from './icons/drop.svg';
import cloud from './icons/cloud.svg';
import offlineNetwork from './icons/offline-network.svg';
import alertStatus from './icons/alert-status.svg';
import warningStatus from './icons/warning-status.svg';
import successStatus from './icons/success-status.svg';
import check from './icons/check.svg';
import plusOutlined from './icons/plus-outlined.svg';
import remove from './icons/remove.svg';
import settingsOutlined from './icons/settings-outlined.svg';
import settings from './icons/settings.svg';
import weatherN from './icons/weather-n.svg';
import weatherS from './icons/weather-s.svg';
import weatherE from './icons/weather-e.svg';
import weatherW from './icons/weather-w.svg';
import weatherNE from './icons/weather-ne.svg';
import weatherNW from './icons/weather-nw.svg';
import weatherSE from './icons/weather-se.svg';
import weatherSW from './icons/weather-sw.svg';
import removeOutlined from './icons/remove-o.svg';
import helpOutlined from './icons/help-o.svg';
import helpOutlinedThick from './icons/help-outlined-thick.svg';
import shield from './icons/shield.svg';
import user from './icons/user.svg';
import star from './icons/star.svg';
import starOutlined from './icons/star-outlined.svg';
import edit from './icons/edit.svg';
import send from './icons/send.svg';
import paperclip from './icons/paperclip.svg';
import cloudDrizzle from './icons/cloud-drizzle.svg';
import cloudLightning from './icons/cloud-lightning.svg';
import cloudRain from './icons/cloud-rain.svg';
import cloudSnow from './icons/cloud-snow.svg';
import wind from './icons/wind.svg';
import sun from './icons/sun.svg';
import moon from './icons/moon.svg';
import partlyCloudyDay from './icons/partly-cloudy-day.svg';
import partlyCloudyNight from './icons/partly-cloudy-night.svg';
import tornado from './icons/tornado.svg';
import fog from './icons/fog.svg';
import noteGray from './icons/notes-grey.svg';
import noteBlue from './icons/notes-blue.svg';
import thermalShock from './icons/thermal-shock.svg';
import heatStress from './icons/heat-stress.svg';
import closeWhite from './icons/close-white.svg';
import connectionLevel1 from './icons/connection-level-1.svg';
import connectionLevel2 from './icons/connection-level-2.svg';
import connectionLevel3 from './icons/connection-level-3.svg';
import connectionLevel4 from './icons/connection-level-4.svg';
import connectionUnknown from './icons/unknown-connection.svg';
import film from './icons/film.svg';
import file from './icons/file.svg';
import activity from './icons/activity.svg';
import clipboard from './icons/clipboard.svg';
import grid from './icons/grid.svg';
import list from './icons/list.svg';
import locations from './icons/locations.svg';
import play from './icons/play-circle.svg';
import download from './icons/download.svg';
import maximize from './icons/maximize.svg';
import close from './icons/x.svg';
import minimize from './icons/minimize.svg';
import animatedClock from './icons/clock-o.svg';
import arrowUp from './icons/chevron-up.svg';
import arrowDown from './icons/chevron-down.svg';
import volume from './icons/volume-1.svg';
import alertTriangle from './icons/alert-triangle.svg';
import heatmap from './icons/heatmap.svg';
import downloadCloud from './icons/download-cloud.svg';
import uploadCloud from './icons/upload-cloud.svg';
import database from './icons/database.svg';
import mapPin from './icons/map-pin.svg';
import filter from './icons/filter.svg';
import toolbox from './icons/toolbox.svg';
import licenseAlert from './icons/license-alert.svg';
import fattening from './icons/fattening.svg';
import nursery from './icons/nursery.svg';
import sow from './icons/sow.svg';
import broiler from './icons/broiler.svg';
import weanToFinish from './icons/wean-to-finish.svg';
import accept from './icons/accept.svg';
import lungs from './icons/lungs.svg';
import episodeDayRed from './icons/episode-day-red.svg';
import episodeDayGreen from './icons/episode-day-green.svg';
import episodeDayYellow from './icons/episode-day-yellow.svg';
import episodeDayGrey from './icons/episode-day-grey.svg';
import recommendationDiagnostics from './icons/recommendation-diagnostics.svg';
import recommendationTreatment from './icons/recommendation-treatment.svg';
import recommendationOngoingTreatment from './icons/recommendation-ongoing-treatment.svg';
import recommendationVeterinarian from './icons/recommendation-veterinarian.svg';
import recommendationEnvironment from './icons/recommendation-environment.svg';
import monitor from './icons/monitor.svg';
import gateway from './icons/gateway.svg';
import logo from './icons/logo.svg';

const Icon = ({ icon, className, ...props }) => (
  <svg {...props} className={cx(s.icon, className)} viewBox={icon.viewBox}>
    <use xlinkHref={`#${icon.id}`} />
  </svg>
);

Icon.icons = {
  arrowLeft,
  arrowLeftWhite,
  calendar,
  clock,
  clockAlert,
  copy,
  remove,
  alertCircle,
  whiteAlertCircle,
  message,
  whiteMessage,
  bell,
  whiteBell,
  greenClose,
  sidepanelCollapse,
  temperatureWarning,
  temperatureAlert,
  humidityWarning,
  humidityAlert,
  coughWarning,
  coughAlert,
  atSign,
  home,
  search,
  refresh,
  cough,
  thermometer,
  drop,
  offlineNetwork,
  alertStatus,
  warningStatus,
  successStatus,
  check,
  plusOutlined,
  settingsOutlined,
  settings,
  removeOutlined,
  helpOutlined,
  helpOutlinedThick,
  shield,
  user,
  star,
  starOutlined,
  edit,
  send,
  paperclip,
  filter,
  /* weather icons */
  weatherN,
  weatherS,
  weatherE,
  weatherW,
  weatherNE,
  weatherNW,
  weatherSE,
  weatherSW,
  cloud,
  cloudDrizzle,
  cloudLightning,
  cloudRain,
  cloudSnow,
  wind,
  sun,
  moon,
  partlyCloudyDay,
  partlyCloudyNight,
  tornado,
  fog,
  noteBlue,
  noteGray,
  thermalShock,
  heatStress,
  closeWhite,
  connectionLevel1,
  connectionLevel2,
  connectionLevel3,
  connectionLevel4,
  connectionUnknown,
  film,
  file,
  /* weather icons */
  activity,
  clipboard,
  grid,
  list,
  locations,
  play,
  download,
  close,
  maximize,
  minimize,
  animatedClock,
  arrowUp,
  arrowDown,
  volume,
  alertTriangle,
  heatmap,
  downloadCloud,
  uploadCloud,
  database,
  mapPin,
  toolbox,
  licenseAlert,
  fattening,
  nursery,
  sow,
  broiler,
  weanToFinish,
  accept,
  lungs,
  episodeDayRed,
  episodeDayGreen,
  episodeDayYellow,
  episodeDayGrey,
  recommendationDiagnostics,
  recommendationTreatment,
  recommendationOngoingTreatment,
  recommendationVeterinarian,
  recommendationEnvironment,
  monitor,
  gateway,
  logo,
};

Icon.defaultProps = {
  className: null,
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(Icon.icons)).isRequired,
};

export default Icon;
