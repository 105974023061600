import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { actions } from 'data';

import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { registrationSuccessPath } from 'constants/urls';
import RegisterForm from 'components/shared/registration';

import qs from 'qs';

import { WithTranslateFormErrors } from 'utils/translation-validation';
import { RegistrationSchema } from 'utils/validation';
// import { FluidPreloader } from 'components/core/preloader';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  register: actions.users.registerUser,
};

class Registration extends React.Component {
  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    register: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {}

  render() {
    const { t } = this.props;

    return (
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: qs.parse(this.props.location.search).email,
          password: '',
          telephone: '',
          language: '',
          timezone: null,
          token: qs.parse(this.props.location.search)['?token'],
        }}
        validationSchema={RegistrationSchema(t)}
        onSubmit={this.props.register}
        render={({ status, ...formikProps }) => {
          if (status) {
            return (
              <Redirect
                to={this.props.location.state ? this.props.location.state.location.pathname : registrationSuccessPath}
              />
            );
          }
          return (
            <WithTranslateFormErrors {...formikProps}>
              <RegisterForm {...formikProps} />
            </WithTranslateFormErrors>
          );
        }}
      />
    );
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Registration),
);
