import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  itemsPagination,
  availabilityTemperatureLoadingProgress,
  availabilityCoughLoadingProgress,
  availabilityStatusLoadingProgress,
  rhsLoadingProgress,
  temperatureLoadingProgress,
  humidityLoadingProgress,
  statsLoadingProgress,
  heatmapTemperatureLoadingProgress,
  heatmapCoughLoadingProgress,
  colorHistoryLoadingProgress,
  colorHistoryItemsPagination,
} from 'data/reducers/monitors';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.monitors;

export const getEntities = state => getState(state).get('entities');

export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (monitors, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.monitor], new Map({ monitors })),
);

export const getItemsTotal = state => itemsPagination.getTotalItems(getState(state));

export const getItemsListLoaded = state => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = state => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = state => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = state => loadingProgress.getLoaded(getState(state));
export const getItemLoading = state => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = state => loadingProgress.getLoadFailed(getState(state));

// Stats

export const getStats = state => getState(state).get('monitorsStats');

export const getMonitorsStatsLoaded = state => statsLoadingProgress.getLoaded(getState(state));
export const getMonitorsStatsLoading = state => statsLoadingProgress.getLoading(getState(state));
export const getMonitorsStatsLoadFailed = state => statsLoadingProgress.getLoadFailed(getState(state));

// Availability

export const getAvailabilityTemperature = state => getState(state).get('availabilityTemperature');
export const getAvailabilityCough = state => getState(state).get('availabilityCough');

export const getAvailabilityTemperatureLoaded = state =>
  availabilityTemperatureLoadingProgress.getLoaded(getState(state));
export const getAvailabilityTemperatureLoading = state =>
  availabilityTemperatureLoadingProgress.getLoading(getState(state));
export const getAvailabilityTemperatureLoadFailed = state =>
  availabilityTemperatureLoadingProgress.getLoadFailed(getState(state));

export const getAvailabilityCoughLoaded = state => availabilityCoughLoadingProgress.getLoaded(getState(state));
export const getAvailabilityCoughLoading = state => availabilityCoughLoadingProgress.getLoading(getState(state));
export const getAvailabilityCoughLoadFailed = state => availabilityCoughLoadingProgress.getLoadFailed(getState(state));

// Availability status-timeline

export const getAvailabilityStatus = state => getState(state).get('availabilityStatus');

export const getAvailabilityStatusLoaded = state => availabilityStatusLoadingProgress.getLoaded(getState(state));
export const getAvailabilityStatusLoading = state => availabilityStatusLoadingProgress.getLoading(getState(state));
export const getAvailabilityStatusLoadFailed = state =>
  availabilityStatusLoadingProgress.getLoadFailed(getState(state));

// RDI

export const getRhs = state => getState(state).get('rhs');

export const getRhsComplex = state => getState(state).get('rhsComplex');

export const getRhsLoaded = state => rhsLoadingProgress.getLoaded(getState(state));
export const getRhsLoading = state => rhsLoadingProgress.getLoading(getState(state));
export const getRhsLoadFailed = state => rhsLoadingProgress.getLoadFailed(getState(state));

// Temperature

export const getTemperature = state => getState(state).get('temperature');

export const getTemperatureComplex = state => getState(state).get('temperatureComplex');

export const getTemperatureLoaded = state => temperatureLoadingProgress.getLoaded(getState(state));
export const getTemperatureLoading = state => temperatureLoadingProgress.getLoading(getState(state));
export const getTemperatureLoadFailed = state => temperatureLoadingProgress.getLoadFailed(getState(state));

// Humidity

export const getHumidity = state => getState(state).get('humidity');

export const getHumidityComplex = state => getState(state).get('humidityComplex');

export const getHumidityLoaded = state => humidityLoadingProgress.getLoaded(getState(state));
export const getHumidityLoading = state => humidityLoadingProgress.getLoading(getState(state));
export const getHumidityLoadFailed = state => humidityLoadingProgress.getLoadFailed(getState(state));

// Heatmap

export const getHeatmapTemperature = state => getState(state).get('heatmapTemperature');

export const getHeatmapTemperatureLoaded = state => heatmapTemperatureLoadingProgress.getLoaded(getState(state));
export const getHeatmapTemperatureLoading = state => heatmapTemperatureLoadingProgress.getLoading(getState(state));
export const getHeatmapTemperatureLoadFailed = state =>
  heatmapTemperatureLoadingProgress.getLoadFailed(getState(state));

export const getHeatmapCough = state => getState(state).get('heatmapCough');

export const getHeatmapCoughLoaded = state => heatmapCoughLoadingProgress.getLoaded(getState(state));
export const getHeatmapCoughLoading = state => heatmapCoughLoadingProgress.getLoading(getState(state));
export const getHeatmapCoughLoadFailed = state => heatmapTemperatureLoadingProgress.getLoadFailed(getState(state));

// Color history

export const getColorHistoryEntities = state => getState(state).get('colorHistory');

export const getColorHistoryItemsList = createImmutableEqualSelector(
  getColorHistoryEntities,
  getState,
  (colorHistoryItems, state) =>
    denormalize(
      colorHistoryItemsPagination.getPageItems(state),
      [schemas.colorHistoryItem],
      new Map({ colorHistoryItems }),
    ),
);

export const getColorHistoryItemsListTotal = state => colorHistoryItemsPagination.getTotalItems(getState(state));

export const getColorHistoryItemsListLoaded = state => colorHistoryLoadingProgress.getLoaded(getState(state));
export const getColorHistoryItemsListLoading = state => colorHistoryLoadingProgress.getLoading(getState(state));
export const getColorHistoryItemsListLoadFailed = state => colorHistoryLoadingProgress.getLoadFailed(getState(state));
