import * as constants from 'data/constants/systems';

export const fetchList = (page = 1, perPage = 100, search) => async (dispatch, _, { api, schema, normalize }) => {
  dispatch({ type: constants.LIST_LOAD_START });
  try {
    const res = await api.get('systems/', { params: { page, perPage, search } });

    const payload = {
      ...res.data,
      ...normalize(res.data.items, [schema.system]),
    };

    dispatch({
      type: constants.LIST_LOAD_SUCCESS,
      payload,
    });
    return true;
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    dispatch({ type: constants.LIST_LOAD_FAILED });

    return e;
  }
};
