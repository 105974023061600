import { fromJS } from 'immutable';
import * as constants from 'data/constants/replacements';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const replacementRequestCreationLoadingProgress = new LoadingProgress('replacementRequestCreation');
export const gatewaysListLoadingProgress = new LoadingProgress('replacementGateways');
export const devicesListLoadingProgress = new LoadingProgress('replacementDevices');
export const availableDevicesListLoadingProgress = new LoadingProgress('replacementAvailableDevices');
export const reasonLoadingProgress = new LoadingProgress('replacementsReasons');
export const sitesListLoadingProgress = new LoadingProgress('replacementsSitesList');
export const listLoadingProgress = new LoadingProgress('replacementsList');
export const itemsPagination = new Pagination('replacementsList');

const mergeData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { replacements },
    } = payload;
    newState.mergeIn(['entities'], fromJS(replacements));
  });

const loadList = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

// const mergeSitesData = (state, payload) =>
//   state.withMutations(newState => {
//     const {
//       entities: { sites },
//     } = payload;
//     newState.mergeIn(['entities'], fromJS(sites));
//   });

const loadSitesList = (state, action) =>
  state.withMutations(newState => {
    // mergeSitesData(newState, action.payload);
    // const { result } = action.payload;
    newState.set('sites', fromJS(action.payload));
    sitesListLoadingProgress.setLoaded(newState);
  });

const loadReasonsList = (state, action) =>
  state.withMutations(newState => {
    newState.set('reasons', fromJS(action.payload));
    reasonLoadingProgress.setLoaded(newState);
  });

// const mergeDevicesData = (state, payload) =>
//   state.withMutations(newState => {
//     const {
//       entities: { devices },
//     } = payload;
//     newState.mergeIn(['devicesEntities'], fromJS(devices));
//   });

const loadDevicesList = (state, action) =>
  state.withMutations(newState => {
    // mergeDevicesData(newState, action.payload);
    // const { result } = action.payload;
    newState.set('devices', fromJS(action.payload));
    devicesListLoadingProgress.setLoaded(newState);
  });

// const mergeAvailableDevicesData = (state, payload) =>
//   state.withMutations(newState => {
//     const {
//       entities: { availableDevices },
//     } = payload;
//     newState.mergeIn(['availableDevicesEntities'], fromJS(availableDevices));
//   });

const loadAvailableDevicesList = (state, action) =>
  state.withMutations(newState => {
    // mergeAvailableDevicesData(newState, action.payload);
    // const { result } = action.payload;
    newState.set('availableDevices', fromJS(action.payload));
    availableDevicesListLoadingProgress.setLoaded(newState);
  });

// const mergeGatewaysData = (state, payload) =>
//   state.withMutations(newState => {
//     const {
//       entities: { gateways },
//     } = payload;
//     newState.mergeIn(['gatewaysEntities'], fromJS(gateways));
//   });

const loadGatewaysList = (state, action) =>
  state.withMutations(newState => {
    // mergeGatewaysData(newState, action.payload);
    // const { result } = action.payload;
    newState.set('gateways', fromJS(action.payload));
    gatewaysListLoadingProgress.setLoaded(newState);
  });

const updateReplacementList = (state, action) =>
  state.withMutations(newState => {
    const {
      entities: { replacements },
    } = action.payload;
    newState.mergeIn(['entities'], fromJS(replacements));
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.REASONS_LOAD_START:
      return reasonLoadingProgress.setLoading(state);
    case constants.REASONS_LOAD_FAILED:
      return reasonLoadingProgress.setLoadFailed(state);
    case constants.REASONS_LOAD_SUCCESS:
      return loadReasonsList(state, action);

    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.SITES_LOAD_START:
      return sitesListLoadingProgress.setLoading(state);
    case constants.SITES_LOAD_FAILED:
      return sitesListLoadingProgress.setLoadFailed(state);
    case constants.SITES_LOAD_SUCCESS:
      return loadSitesList(state, action);

    case constants.GATEWAYS_LOAD_START:
      return gatewaysListLoadingProgress.setLoading(state);
    case constants.GATEWAYS_LOAD_FAILED:
      return gatewaysListLoadingProgress.setLoadFailed(state);
    case constants.GATEWAYS_LOAD_SUCCESS:
      return loadGatewaysList(state, action);

    case constants.DEVICES_LOAD_START:
      return devicesListLoadingProgress.setLoading(state);
    case constants.DEVICES_LOAD_FAILED:
      return devicesListLoadingProgress.setLoadFailed(state);
    case constants.DEVICES_LOAD_SUCCESS:
      return loadDevicesList(state, action);

    case constants.AVAILABLE_DEVICES_LOAD_START:
      return availableDevicesListLoadingProgress.setLoading(state);
    case constants.AVAILABLE_DEVICES_LOAD_FAILED:
      return availableDevicesListLoadingProgress.setLoadFailed(state);
    case constants.AVAILABLE_DEVICES_LOAD_SUCCESS:
      return loadAvailableDevicesList(state, action);

    case constants.REQUEST_CREATION_START:
      return replacementRequestCreationLoadingProgress.setLoading(state);
    case constants.REQUEST_CREATION_FAILED:
      return replacementRequestCreationLoadingProgress.setLoadFailed(state);
    case constants.REQUEST_CREATION_SUCCESS:
      return replacementRequestCreationLoadingProgress.setLoaded(state);

    case constants.REPLACEMENTS_UPDATE_SUCCESS:
    case constants.REPLACEMENTS_CANCEL_SUCCESS:
      return updateReplacementList(state, action);
    default:
      return state;
  }
};
