import React from 'react';
import PropTypes from 'prop-types';

class BreadcrumbsProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    context: PropTypes.shape({
      Provider: PropTypes.object.isRequired,
      Consumer: PropTypes.object.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      routes: [],
    };
  }

  register = route => {
    this.setState(state => ({ routes: [...state.routes, route] }));
  };

  unregister = route => {
    this.setState(state => ({ routes: state.routes.filter(rt => rt.path !== route.path) }));
  };

  update = route => {
    this.setState(state => ({ routes: state.routes.map(rt => (rt.path === route.path ? route : rt)) }));
  };

  render() {
    const { Provider } = this.props.context;
    return (
      <Provider
        value={{ routes: this.state.routes, register: this.register, unregister: this.unregister, update: this.update }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export default BreadcrumbsProvider;
