import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '../text';
import { colors, sizes } from '../constants';

import s from './styles.scss';

const FieldControl = ({
  label,
  labelStyle,
  inputComponent,
  innerComponentStyle,
  style,
  className,
  inputClassName,
  error,
  errorColor,
  itemKey,
  ...props
}) => {
  const FormInputField = inputComponent;
  return (
    <div className={cx(className, s.fieldControl)}>
      {label && (
        <Text size={Text.size.xs} className={s.fieldControlLabel} style={labelStyle} weight={Text.weight.regular}>
          {label}
        </Text>
      )}
      <FormInputField
        key={itemKey}
        innerComponentStyle={innerComponentStyle}
        errorColor={errorColor}
        error={error}
        {...props}
        className={inputClassName}
      />
      {error && (
        <Text
          size={Text.size.xs}
          style={{
            marginTop: sizes.base / 2,
            ...(FormInputField.type ? FormInputField.type.style.errorText : { color: colors.error }),
            ...(errorColor && { color: errorColor }),
          }}
        >
          {error}
        </Text>
      )}
    </div>
  );
};

FieldControl.propTypes = {
  id: PropTypes.string,
  style: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelStyle: PropTypes.shape({}),
  innerComponentStyle: PropTypes.shape({}),
  itemKey: PropTypes.string.isRequired,
  inputComponent: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorColor: PropTypes.string,
};

FieldControl.defaultProps = {
  id: null,
  style: null,
  className: null,
  inputClassName: null,
  innerComponentStyle: null,
  label: null,
  labelStyle: null,
  error: null,
  errorColor: null,
};

export default FieldControl;
