import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'data';

import { basePath } from 'constants/urls';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  logout: actions.session.logout,
};

class SignOut extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Redirect to={basePath} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignOut);
