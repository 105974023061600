const prefix = type => `opu/profile/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const FAQ_LOAD_START = prefix('FAQ_LOAD_START');
export const FAQ_LOAD_SUCCESS = prefix('FAQ_LOAD_SUCCESS');
export const FAQ_LOAD_FAILED = prefix('FAQ_LOAD_FAILED');

export const PROFILE_LOAD_START = prefix('PROFILE_LOAD_START');
export const PROFILE_LOAD_SUCCESS = prefix('PROFILE_LOAD_SUCCESS');
export const PROFILE_LOAD_FAILED = prefix('PROFILE_LOAD_FAILED');

export const LANGUAGES_LOAD_START = prefix('LANGUAGES_LOAD_START');
export const LANGUAGES_LOAD_SUCCESS = prefix('LANGUAGES_LOAD_SUCCESS');
export const LANGUAGES_LOAD_FAILED = prefix('LANGUAGES_LOAD_FAILED');
