import {
  loadingProgress,
  loadingFaqProgress,
  settingsLoadingProgress,
  languagesLoadingProgress,
} from 'data/reducers/profile';

const getState = state => state.profile;

export const getItemLoaded = state => loadingProgress.getLoaded(getState(state));
export const getItemLoading = state => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = state => loadingProgress.getLoadFailed(getState(state));

export const getItem = state => getState(state).get('profile');

export const getItemsPerPage = state => getItem(state).get('paging_size');

// Settings

export const getSettingsLoaded = state => settingsLoadingProgress.getLoaded(getState(state));
export const getSettingsLoading = state => settingsLoadingProgress.getLoading(getState(state));
export const getSettingsLoadFailed = state => settingsLoadingProgress.getLoadFailed(getState(state));

export const getSettings = state => getState(state).get('settings');

// Languages

export const getLanguagesLoaded = state => languagesLoadingProgress.getLoaded(getState(state));
export const getLanguagesLoading = state => languagesLoadingProgress.getLoading(getState(state));
export const getLanguagesLoadFailed = state => languagesLoadingProgress.getLoadFailed(getState(state));

export const getLanguages = state => getState(state).get('languages');

export const getFaq = state => getState(state).get('faq');

export const getFaqLoaded = state => loadingFaqProgress.getLoaded(getState(state));
export const getFaqLoading = state => loadingFaqProgress.getLoading(getState(state));
export const getFaqLoadFailed = state => loadingFaqProgress.getLoadFailed(getState(state));
