import { fromJS } from 'immutable';
import * as constants from 'data/constants/users';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('user');
export const listLoadingProgress = new LoadingProgress('userList');
export const itemsPagination = new Pagination('userList');

export const suppliersListLoadingProgress = new LoadingProgress('userSuppliersList');
export const userRolesListLoadingProgress = new LoadingProgress('userRolesList');
export const rolesLoadingProgress = new LoadingProgress('roles');

const mergeData = (state, payload) =>
  state.withMutations(newState => {
    const {
      entities: { users },
    } = payload;
    newState.mergeIn(['entities'], fromJS(users));
  });

const loadList = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations(newState => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const loadSuppliers = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['suppliers'], fromJS(action.payload));
    suppliersListLoadingProgress.setLoaded(newState);
  });

const loadUserRoles = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['userRoles'], fromJS(action.payload));
    userRolesListLoadingProgress.setLoaded(newState);
  });

const loadRoles = (state, action) =>
  state.withMutations(newState => {
    newState.setIn(['roles'], fromJS(action.payload));
    rolesLoadingProgress.setLoaded(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);

    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.SUPPLIERS_LIST_LOAD_START:
      return suppliersListLoadingProgress.setLoading(state);
    case constants.SUPPLIERS_LIST_LOAD_FAILED:
      return suppliersListLoadingProgress.setLoadFailed(state);
    case constants.SUPPLIERS_LIST_LOAD_SUCCESS:
      return loadSuppliers(state, action);

    case constants.ROLES_LIST_LOAD_START:
      return userRolesListLoadingProgress.setLoading(state);
    case constants.ROLES_LIST_LOAD_FAILED:
      return userRolesListLoadingProgress.setLoadFailed(state);
    case constants.ROLES_LIST_LOAD_SUCCESS:
      return loadUserRoles(state, action);

    case constants.ROLES_LOAD_START:
      return rolesLoadingProgress.setLoading(state);
    case constants.ROLES_LOAD_FAILED:
      return rolesLoadingProgress.setLoadFailed(state);
    case constants.ROLES_LOAD_SUCCESS:
      return loadRoles(state, action);

    default:
      return state;
  }
};
