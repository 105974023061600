import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

const Avatar = ({ className, src, firstname, lastname, size, ...props }) => {
  const fn = firstname || 'Soundtalks';
  const ln = lastname || 'User';

  return (
    <div {...props} className={cx(s.avatar, size, className)} style={{ backgroundImage: `url(${src})` }}>
      {!src && (
        <div className={cx(s.avatarFallback)}>{`${fn.charAt(0).toUpperCase()}${ln.charAt(0).toUpperCase()}`}</div>
      )}
    </div>
  );
};

Avatar.sizes = {
  huge: s.avatarHuge,
  medium: s.avatarMedium,
  small: s.avatarSmall,
};

Avatar.defaultProps = {
  className: null,
  src: null,
  firstname: 'Soundtalks',
  lastname: 'User',
  size: PropTypes.string,
};

Avatar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  size: null,
};

export default Avatar;
