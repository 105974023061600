import { Map } from 'immutable';
import * as constants from 'data/constants/session';
import LoadingProgress from 'data/utils/reducers/loading';

export const tokenLoading = new LoadingProgress('token');
export const refreshLoading = new LoadingProgress('refresh');

const login = (state, action) =>
  state.withMutations(newState => {
    const { access, refresh } = action.payload;
    newState.set('access', access);
    newState.set('refresh', refresh);
    tokenLoading.setLoaded(newState);
  });

const refresh = (state, action) =>
  state.withMutations(newState => {
    const { access } = action.payload;
    newState.set('access', access);
    refreshLoading.setLoaded(newState);
  });

const clear = state =>
  state.withMutations(newState => {
    tokenLoading.clear(newState);
    refreshLoading.clear(newState);
    newState.delete('access');
    newState.delete('refresh');
  });

const initialState = new Map({});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN:
      return tokenLoading.setLoading(state);
    case constants.LOGIN_FAILED:
      return tokenLoading.setLoadFailed(state);
    case constants.LOGIN_SUCCESS:
      return login(state, action);

    case constants.REFRESH:
      return refreshLoading.setLoading(state);
    case constants.REFRESH_FAILED:
      return refreshLoading.setLoadFailed(state);
    case constants.REFRESH_SUCCESS:
      return refresh(state, action);

    case constants.LOGOUT:
      return clear(state);
    default:
      return state;
  }
};
