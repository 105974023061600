import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  rhsLoadingProgress,
  temperatureLoadingProgress,
  monitorsListLoadingProgress,
  monitorsItemsPagination,
  monitorsRhsLoadingProgress,
  monitorsTemperatureLoadingProgress,
  monitorsHumidityLoadingProgress,
  humidityLoadingProgress,
  monitorsHeatmapLoadingProgress,
} from 'data/reducers/airspaces';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = state => state.airspaces;

// RDI

export const getRhs = state => getState(state).get('rhs');

export const getRhsLoaded = state => rhsLoadingProgress.getLoaded(getState(state));
export const getRhsLoading = state => rhsLoadingProgress.getLoading(getState(state));
export const getRhsLoadFailed = state => rhsLoadingProgress.getLoadFailed(getState(state));

// Temperature

export const getTemperature = state => getState(state).get('temperature');

export const getTemperatureLoaded = state => temperatureLoadingProgress.getLoaded(getState(state));
export const getTemperatureLoading = state => temperatureLoadingProgress.getLoading(getState(state));
export const getTemperatureLoadFailed = state => temperatureLoadingProgress.getLoadFailed(getState(state));

// Humidity

export const getHumidity = state => getState(state).get('humidity');

export const getHumidityLoaded = state => humidityLoadingProgress.getLoaded(getState(state));
export const getHumidityLoading = state => humidityLoadingProgress.getLoading(getState(state));
export const getHumidityLoadFailed = state => humidityLoadingProgress.getLoadFailed(getState(state));

// Monitors

export const getMonitors = state => getState(state).get('monitors');

export const getMonitorsList = createImmutableEqualSelector(getMonitors, getState, (monitors, state) =>
  denormalize(monitorsItemsPagination.getPageItems(state), [schemas.monitor], new Map({ monitors })),
);

export const getMonitorsListLoaded = state => monitorsListLoadingProgress.getLoaded(getState(state));
export const getMonitorsListLoading = state => monitorsListLoadingProgress.getLoading(getState(state));
export const getMonitorsListLoadFailed = state => monitorsListLoadingProgress.getLoadFailed(getState(state));

// Monitors temperature and rhs

export const getMonitorsRhsLoaded = state => monitorsRhsLoadingProgress.getLoaded(getState(state));
export const getMonitorsRhsLoading = state => monitorsRhsLoadingProgress.getLoading(getState(state));
export const getMonitorsRhsLoadFailed = state => monitorsRhsLoadingProgress.getLoadFailed(getState(state));

export const getMonitorsTemperatureLoaded = state => monitorsTemperatureLoadingProgress.getLoaded(getState(state));
export const getMonitorsTemperatureLoading = state => monitorsTemperatureLoadingProgress.getLoading(getState(state));
export const getMonitorsTemperatureLoadFailed = state =>
  monitorsTemperatureLoadingProgress.getLoadFailed(getState(state));

export const getMonitorsHumidityLoaded = state => monitorsHumidityLoadingProgress.getLoaded(getState(state));
export const getMonitorsHumidityLoading = state => monitorsHumidityLoadingProgress.getLoading(getState(state));
export const getMonitorsHumidityLoadFailed = state => monitorsHumidityLoadingProgress.getLoadFailed(getState(state));

//  Monitors heatmap

export const getMonitorsHeatmap = state => getState(state).get('monitorsHeatmap');

export const getMonitorsHeatmapLoaded = state => monitorsHeatmapLoadingProgress.getLoaded(getState(state));
export const getMonitorsHeatmapLoading = state => monitorsHeatmapLoadingProgress.getLoading(getState(state));
export const getMonitorsHeatmapLoadFailed = state => monitorsHeatmapLoadingProgress.getLoadFailed(getState(state));
