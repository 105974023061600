import { validationProgress, executionProgress } from 'data/reducers/subscriptions';

const getState = state => state.subscriptions;

export const getValidationLoaded = state => validationProgress.getLoaded(getState(state));
export const getValidationLoading = state => validationProgress.getLoading(getState(state));
export const getValidationLoadFailed = state => validationProgress.getLoadFailed(getState(state));

export const getValidation = state => getState(state).get('validation');

export const getExecutionLoaded = state => executionProgress.getLoaded(getState(state));
export const getExecutionLoading = state => executionProgress.getLoading(getState(state));
export const getExecutionLoadFailed = state => executionProgress.getLoadFailed(getState(state));
