import React from 'react';
import PropTypes from 'prop-types';

class AnchorInternal extends React.Component {
  static propTypes = {
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
    // eslint-disable-next-line
    wrapperStyle: PropTypes.object,
  };

  static defaultProps = {
    wrapperStyle: null,
  };

  componentDidMount() {
    this.props.register({
      link: this.props.link,
      path: this.props.path,
      component: this.props.component,
      wrapperStyle: this.props.wrapperStyle,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.component !== prevProps.component || this.props.link !== prevProps.link) {
      this.props.update({
        link: this.props.link,
        path: prevProps.path,
        component: this.props.component,
        wrapperStyle: this.props.wrapperStyle,
      });
    }
  }

  componentWillUnmount() {
    this.props.unregister({
      link: this.props.link,
      path: this.props.path,
      component: this.props.component,
      wrapperStyle: this.props.wrapperStyle,
    });
  }

  render() {
    return null;
  }
}

const BreadcrumbsAnchor = ({ context, ...props }) => {
  const { Consumer } = context;
  return (
    <Consumer>
      {value => (
        <AnchorInternal register={value.register} unregister={value.unregister} update={value.update} {...props} />
      )}
    </Consumer>
  );
};

BreadcrumbsAnchor.propTypes = {
  context: PropTypes.shape({
    Provider: PropTypes.object.isRequired,
    Consumer: PropTypes.object.isRequired,
  }).isRequired,
};

export default BreadcrumbsAnchor;
