import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

const HeaderShared = ({ className, children, ...props }) => (
  <header {...props} className={cx(s.header, className)}>
    {children}
  </header>
);

HeaderShared.defaultProps = {
  className: null,
  children: null,
};

HeaderShared.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default HeaderShared;
