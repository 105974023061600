import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
  basePath,
  signInPath,
  signOutPath,
  baseErrorPath,
  resetPasswordPath,
  recoverPasswordPath,
  resetPasswordSuccessPath,
  registrationPath,
  registrationSuccessPath,
  notFoundPath,
} from 'constants/urls';

import SignIn from 'containers/shared/sign-in';
import SignOut from 'containers/shared/sign-out';
import ResetPassword from 'containers/shared/reset-password';
import ResetPasswordSuccess from 'components/shared/reset-password-success';
import RecoverPassword from 'containers/shared/recover-password';
import Registration from 'containers/shared/registration';
import RegistrationSuccess from 'components/shared/registration-success';

import SharedLayout from 'components/shared/layout';

class Shared extends React.Component {
  componentDidCatch(error, errorInfo) {
    this.props.history.push({ pathname: baseErrorPath, state: { error, errorInfo } });
  }

  render() {
    return (
      <SharedLayout>
        <Switch>
          <Route exact path={signInPath} component={SignIn} />
          <Route exact path={resetPasswordPath} component={ResetPassword} />
          <Route exact path={resetPasswordSuccessPath} component={ResetPasswordSuccess} />
          <Route exact path={recoverPasswordPath} component={RecoverPassword} />
          <Route exact path={baseErrorPath} component={<div>Error</div>} />
          <Route exact path={signOutPath} component={SignOut} />
          <Route exact path={registrationPath} component={Registration} />
          <Route exact path={registrationSuccessPath} component={RegistrationSuccess} />
          <Redirect exact from={basePath} to={signInPath} />
          <Redirect to={notFoundPath} />
        </Switch>
      </SharedLayout>
    );
  }
}

Shared.propTypes = {
  history: PropTypes.func.isRequired,
};

export default hot(module)(Shared);
